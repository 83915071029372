import { RouterProvider } from 'react-router-dom';
import { ThemeProvider, createTheme } from '@mui/material/styles';

import '@fontsource-variable/montserrat';

import AppRouter from '../router';

const primaryColor = '#3457a7'
const secondaryColor = '#5ce1e6'
const errorColor = '#d32f32'
const primaryTextColor = '#000000'
const secondaryTextColor = "#3457a7"

const theme = createTheme({
  palette: {
    mode: 'light',
    primary: {
      main: primaryColor,
    },
    secondary: {
      main: secondaryColor
    },
    error: {
      main: errorColor,
    },
  },

  typography: {
    fontFamily: [
      '"Montserrat Variable"', 
      'sans-serif',
      '-apple-system',
      'BlinkMacSystemFont',
      '"Segoe UI"',
      'Roboto',
      '"Helvetica Neue"',
      'Arial',
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(','),
    fontSize: 16,
  },

  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          borderRadius: 40,
          padding: '5px 30px',
          textTransform: 'none',
          fontSize: '18px',
          fontWeight: 'bold',
          "&:hover": {
            backgroundColor: secondaryColor,
          },
        },
      }, 
    },
    
    MuiIconButton: {
      styleOverrides: {
        root: {
          "&:hover": {
            color: secondaryColor,
          },
        }
      }
    },

    MuiPaper: {
      styleOverrides: {
        root: {
          boxShadow: '0 0 70px 20px rgb(245 248 255)'
        },
      },
      defaultProps: {
        square: true
      }
    },

    MuiListItem: {
      styleOverrides: {
        root: {
          paddingLeft: '0px',
          paddingRight: '0px'
        },
      },
    },
  },
})

const App = () => {

  return (
    <ThemeProvider theme={theme}>
      <RouterProvider router={AppRouter} />
    </ThemeProvider>
  );
}


export default (App);