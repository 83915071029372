import React from "react";

import { makeStyles } from "@mui/styles"
import { Grid, Typography, Paper, Button } from "@mui/material";

import { UploadIcon, ViewIcon, CheckoutIcon } from "../../../media";
import { styles } from "../../../app";
import { UploadMol2 } from "../../start-cgenff-job/utils";

const useStyles = makeStyles(() => ({
  image: {
    display: 'flex',
    justifyContent: 'center',
  },
  image2: {
    display: 'flex',
    justifyContent: 'center',
    padding: '20px'
  },
}));

const Quickstart = ({ userId, userType, jobCount, resetJob, saveMol2FileName, open, setOpen, saveCenter }) => {

  const classes = useStyles();
  // const navigate = useNavigate()

  const iconSize = '120px'

  const handleClick = () => {
    resetJob()
    setOpen(true)
  }

  return (
    <Paper style={{
      padding: '20px',
      flexDirection: 'column',
      alignItems: "center",
    }}>
      <Typography padding={4}>

        <Grid container style={{ height: '400px' }}>

          <Grid item xs={12} style={styles.Title}>
            QUICK START
          </Grid>

          <Grid item xs={12}>
            <Typography align='left' gutterBottom='10px'>
              Follow these 3 easy steps to get parameters for your molecule
            </Typography>
          </Grid>

          <Grid container item spacing={2}>

            <Grid item xs={4}>

              <div className={classes.image}>
                <Button onClick={handleClick} sx={{ borderRadius: 20, padding: '20px' }}>
                  <img alt="" src={UploadIcon} width={iconSize} />
                </Button>
              </div>

              <Typography align="center" fontWeight='bold' padding={1} paddingBottom={2} >Step 1</Typography>

              <Typography align="center" paddingLeft={2} paddingRight={2}>
                Upload a Mol2 file of your molecule
              </Typography>

            </Grid>

            <Grid item xs={4}>

              <div className={classes.image2}>
                <img alt="" src={ViewIcon} width={iconSize} />
              </div>

              <Typography align="center" fontWeight='bold' padding={1} paddingBottom={2}>Step 2</Typography>

              <Typography align="center" paddingLeft={2} paddingRight={2}>
                Visualize the molecule and select options
              </Typography>

            </Grid>

            <Grid item xs={4}>

              <div className={classes.image2}>
                <img alt="" src={CheckoutIcon} width={iconSize} />
              </div>

              <Typography align="center" fontWeight='bold' padding={1} paddingBottom={2} >Step 3</Typography>

              <Typography align="center" paddingLeft={2} paddingRight={2}>
                {userType==='Academia' ? 'Get molecule parameters' : 'Pay and get molecule parameters'}
                
              </Typography>

            </Grid>

          </Grid>

          <UploadMol2
            userId={userId}
            userType={userType}
            jobCount={jobCount}
            open={open}
            setOpen={setOpen}
            saveMol2FileName={saveMol2FileName}
            saveCenter={saveCenter}
          />

        </Grid>
      </Typography>
    </Paper>
  )
}
export default (Quickstart);