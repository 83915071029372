import React from "react";
import { FormControl, TextField, FormControlLabel, Switch, Tooltip } from "@mui/material";

const Options = ({ stem, setJobName, handleOptionsChange, saveJobOptions, jobOptionState, jobOptionDisabled }) => {
  const saveNameChange = (e) => {
    setJobName(e.target.value !== null ? e.target.value : stem);
  };

  const renderSwitch = (name, label, description) => (
    <Tooltip placement="right-start" title={description}>
      <FormControlLabel
        control={
          <Switch
            name={name}
            checked={jobOptionState[name]}
            disabled={jobOptionDisabled}
            onChange={(e) => {
              handleOptionsChange(e);
              saveJobOptions(e);
            }}
          />
        }
        label={label}
      />
    </Tooltip>
  );

  return (
    <div>
      <TextField
        type="text"
        label="Job Name"
        InputLabelProps={{ shrink: true }}
        placeholder={`Input a custom name or use ${stem} for job name`}
        name="customName"
        variant="outlined"
        size="small"
        fullWidth
        inputProps={{ maxLength: 50 }}
        onChange={(e) => saveNameChange(e)}
      />

      <FormControl margin="normal">
        {/* {renderSwitch("gromacs", "Add GROMACS Format", "“Add GROMACS Format” will generate parameters in GROMACS format after the molecule is successfully processed by the CGenFF program.")} */}

        {renderSwitch("mol2out", "Output Mol2", "“Output Mol2” will generate a processed Mol2 file of the molecule after assigning your parameters. Atom names will be modified in accordance with CHARMM formatting.")}

        {renderSwitch("warning", "Include Warnings", "“Include Warnings” will display any warning information for resulting from the CGenFF program execution.")}

        {renderSwitch("debug", "Include Debug Info", "“Include Debug Info” will display debugging information.")}

        {renderSwitch("copyParams", "Copy Existing Params", "“Copy Existing Params” will copy existing CHARMM General Force Field parameters to the output.")}
      </FormControl>
    </div>
  );
};

export default Options;
