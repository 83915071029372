import { Dna } from 'react-loader-spinner'

const LoadingDna = ({size}) => {
	return (
		<div style={{ display: 'flex', justifyContent: 'center' }}>
			<Dna
				visible={true}
				height={size}
				width={size}
				ariaLabel="dna-loading"
				wrapperStyle={{}}
				wrapperClass="dna-wrapper"
			/>
		</div>
	)
}

export default LoadingDna