import React from "react";

import { AppBar, Grid } from "@mui/material";

import { styles } from "../styles";
import { LogoClickable } from "../../components";

const Header = () => {

  return (
    <AppBar style={styles.Header}>
      <Grid style={styles.Logo}>
        <LogoClickable/>
      </Grid>
    </AppBar>
  );
}


export default (Header);