import React, { useEffect, useState } from "react";
import axios from 'axios';

import { format } from "date-fns";

import { makeStyles } from "@mui/styles";
import { Grid, Typography, Paper } from "@mui/material";

import { LoadingDna } from "../../../components";
import JobHistoryFormat from "./job-history-format";

import { styles } from "../../../app";

const useStyles = makeStyles(() => ({
	scroll: {
		display: 'flex',
		flexDirection: 'column',
		rowGap: '10px',
		height: '700px',
		overflow: 'scroll',
		scrollBehavior: 'smooth',
	},
}));

const fetchJobInfo = async (userId, setJobs, setIsLoading, setErrMessage) => {
	await axios.post('/jobs/retrival/fetchJobsByUserId', { 'userId': userId, 'displayResultsInConsole': false })
		.then((res) => {
			setJobs(res.data)
			setIsLoading(false)
		}).catch(() => {
			setJobs([])
			setErrMessage("There was a problem loading the job history! Please try logging out and in again.")
			setIsLoading(false)
		})
}

const JobHistory = ({ userId }) => {

	const classes = useStyles();
	const [listOfJobs, setListOfJobs] = useState([]);
	const [refresh, setRefresh] = useState(false)
	const [isLoading, setIsLoading] = useState(true)
	const [errMessage, setErrMessage] = useState("");

	// useEffect(() => {
	// 	const intervalId = setInterval(() => {
	// 		fetchJobInfo(userId, setListOfJobs, setIsLoading, setErrMessage);
	// 	}, 30000);
	// 	return () => clearInterval(intervalId);
	// }, []);

	useEffect(() => {
		fetchJobInfo(userId, setListOfJobs, setIsLoading, setErrMessage);
		setRefresh(false)
	}, [userId, refresh]);

	let jobInfo = []
	let jobName = []
	let fileName = []
	let submitted = []
	let outputS3Location = []
	let jobId = []

	listOfJobs.forEach((job) => {
		jobInfo.push(JSON.parse(job.Job_Options_Json.replace(/'/g, '"').replaceAll('True', 'true')))
		jobId.push(job.Job_ID)
		jobName.push(job.Job_Name)
		fileName.push(job.File_Name)
		submitted.push((format(new Date(`${job.Submitted_Date_Time}` + 'Z'), "yyyy-MMM-dd H:mm")).toString())
		outputS3Location.push(job.Output_S3_Location)
	});

	return (
		<Paper style={{
			padding: '20px',
			flexDirection: 'column',
			alignItems: "center",
			width: '450px'
		}}>
			<Typography padding={4}>
				<Grid container style={styles.Title}>
					JOB HISTORY
				</Grid>

				{isLoading ?
					<LoadingDna size='60' />
					:
					listOfJobs.length <= 0 ?
						errMessage !== "" ?
							<Typography style={{ fontSize: "20px", color: 'red' }}>
								<b>{errMessage}</b>
							</Typography>
							:
							<Typography style={{ fontSize: "20px" }}>
								<b>No completed jobs</b>
							</Typography>
						:
						<Typography component="span" className={classes.scroll}>
							{jobInfo.map((_job, index) =>
								<JobHistoryFormat
									key={index}
									name={jobName[index] ? jobName[index] : fileName[index]}
									downloadLoc={outputS3Location[index]}
									jobInfo={jobInfo[index]}
									jobId={jobId[index]}
									submitted={submitted[index]}
									refresh={refresh}
									setRefresh={setRefresh}
								/>
							)}
						</Typography>
				}
			</Typography>
		</Paper>
	);
};

export default (JobHistory);