const whiteList = [
  ".edu",
  ".edu.",
  ".ac.",
  "s.unibuc.ro",
  "bioc.uzh.ch",
  "student.usm.my",
  "studenti.unitn.it",
  "kuleuven.be",
  "etu.univ-lorraine.fr",
  "etu.unige.ch",
  "ufv.br",
  "dsf.unica.it",
  "quimica.unam.mx",
  "epfl.ch",
  "uem.br",
  "ufpe.nr",
  "ac.upatras.gr",
  "ciencias.unam.mx",
  "edu.itmo.ru",
  "esib.butantan.gov.br",
  "fau.de",
  "furg.br",
  "helsinki.fi",
  "hsleiden.nl",
  "mail.dcu.ie",
  "smail.bcw.h-brs.de",
  "studenti.unina.it",
  "tecnico.ulisboa.pt",
  "ualberta.ca",
  "ufmg.br",
  "uwaterloo.ca",
  "studenti.unisa.it",
  "atu.ie",
  "etu.u-paris.fr",
  "etu.unistra.fr",
  "farmaci.uu.se",
  "fizyka.umk.pl",
  "grad.ufsc.br",
  "knu.ua",
  "nabi.res.in",
  "sfedu.ru",
  "tpu.ru",
  "unifesp.br",
  "unipd.it",
  "unipv.it",
  "univ-lorraine.fr",
  "usp.br",
  "uvm.cl",
  "yuhs.ac", 
  "mkuniversity.org",
  "studenti.unimi.it",
  "kkumail.com",
  "student.crsd.org",
  "univ-lille.fr",
  "u-paris.fr",
  "uzh.ch",
  "campus.tu-berlin.de",
  "ucm.cl",
  "chemistry.msu.ru",
  "kemi.dtu.dk",
  "tum.de",
  "biotec.rwth-aachen.de",
  "univ-lyon1.fr",
  "univ-nantes.fr",
  "ulaval.ca",
  "usms.ma",
  "um.si",
  "univ-paris-diderot.fr",
  "uniupo.it",
  "irapuato.tecnm.mx",
  "instec.cu",
  "irapuato.tecnm.mx",
  "fkit.unizg.hr",
  "ku.th",
  "udec.cl",
  "bsu.by",
  "students.uni-mainz.de",
  "stud.uni-saarland.de",
  "uliege.be",
  "ufpr.br",
  "student.dtu.dk",
  "unige.ch",
  "g.nsu.ru",
  "icf.unam.mx",
  "fu-berlin.de",
  "uniroma1.it",
  "kau.ac.kr",
  "unn.ru",
  "aluno.unb.br",
  "ugr.es",
  "unife.it",
  "medizin.uni-leipzig.de",
  "ntnu.no",
  "icm.uu.se",
  "nipgr.ac.in",
  "usach.cl",
  "oci.uni-hannover.de",
  "ciad.mx",
  "kau.kr",
  "cua.uam.mx",
  "ueb.cas.cz",
  "biol.uoa.gr",
  "sickkids.ca",
  "vitstudent.ac.in",
  "ils.res.in",
  "studenti.unipr.it",
  "tifrh.res.in",
  "pharm.auth.gr",
  "connect.ust.hk",
  "mail.mcgill.ca",
  "univ-tlse3.fr",
  "unive.it",
  "unipr.it",
  "agroparistech.fr",
  "xanum.uam.mx",
  "students.uniroma2.eu",
  "imm.ac.cn",
  "stud.uni-frankfurt.de",
  "shu.edu.cn",
  "mail.utoronto.ca",
  "oulu.fi",
  "hhu.de",
  "spcpu.ru",
  "usm.my",
  "unimi.it",
  "ils.res.in",
  "uji.es",
  "ibt.unam.mx",
  "usherbrooke.ca",
  "ens.psl.eu",
  "niperkolkata.edu.in",
  "universityofgalway.ie",
  "niuitmo.ru",
  "uv.cl",
  "etu.u-pec.fr",
  "liu.se",
  "eheb.ma",
  "stowers.org",
  "univ-djelfa.dz",
  "kisti.re.kr",
  "students.uu.nl",
  "ugent.be",
  "biof.ufrj.br",
  "umanitoba.ca",
  "alumnos.udg.mx",
  "email.umfiasi.ro",
  "univ-djelfa.dz",
  "kau.se",
  "chem.au.dk",
  "sgu.ru",
  "unibas.ch",
  "etu.u-bourgogne.fr",
  "edu.unige.it",
  "d.umfiasi.ro",
  "universite-paris-saclay.fr",
  "stud.uni-heidelberg.de",
  "utoronto.ca",
  "ucalgary.ca",
  "rgcb.res.in",
  "acsir.res.in",
  "uni-goettingen.de",
  "cuchd.in",
  "universitadipavia.it",
  "ufes.br",
  "banasthali.in",
  "saveetha.com",
  "mun.ca",
  "univ-chlef.dz",
  "cinvestav.mx",
  "univ-reims.fr",
  "alu.ufc.br",
  "stu.dzu.edu.cn",
  "univ-tlemcen.dz",
  "discente.ufg.br",
  "ox.ac.uk",
  "stu.xmu.edu.cn",
  "chem.pmf.hr",
  "univaq.it",
  "science.unideb.hu",
  "campus.fct.unl.pt",
  "ufpe.br",
  "ufrj.br",
  "gitam.in",
  "iacs.res.in",
  "qsar.chem.msu.ru",
  "ext.uv.es",
  "kunainital.ac.in",
  "iq.ufrj.br",
  "kunainital.ac.in",
  "unibe.ch",
  "izt.uam.mx",
  "uni-tuebingen.de",
  "ugto.mx",
  "rub.de",
  "student.uni-siegen.de",
  "studentmail.ul.ie",
  "tu-dresden.de",
  "dtu.dk",
  "tuni.fi",
  "sgu.ru",
  "utem.cl",
  "ipn.mx",
  "engenharia.ufjf.br",
  "imc.macro.ru",
  "uni-due.de",
  "ucd.ie",
  "usask.ca",
  "estudante.ufscar.br",
  "univ-st-etienne.fr",
  "um6ss.ma",
  "icb.ufpa.br",
  "ehu.uah.es",
  "tu-braunschweig.de",
  "etu.umontpellier.fr",
  "nanoctr.cn",
  "ipn.mx",
  "alumni.uniroma2.eu",
  "uni-greifswald.de",
  "matfyz.cuni.cz",
  "s.upc.edu.cn",
  "alumno.buap.mx",
  "ucdb.br",
  "uni-potsdam.de",
  "student.amity.edu",
  "unisa.it",
  "urfu.me",
  "kgut.ac.ir",
  "earlycharm.com",
  "chalmers.se",
  "uwo.ca",
  "uni-siegen.de",
  "ug.uchile.cl",
  "estudante.ufla.br",
  "uni-bayreuth.de",
  "sund.ku.dk",
  "sgtuniversity.org",
  "ufrgs.br",
  "tifr.res.in",
  "etu.toulouse-inp.fr",
  "constructor.university",
  "ug.uchile.cl",
  "shivajiscamt.org",
  "vscht.cz",
  "estudante.ufla.br",
  "silcsbio.com",
  "comunidad.unam.mx",
  "koyauniversity.org",
  "pharm.uoa.gr",
  "alumnos.viu.es",
  "campus.lmu.de",
  "kluniversity.in",
  "mail.ntua.gr",
  "umfcluj.ro",
  "accademialucchese.it",
  "dokt.p.lodz.pl",
  "unimib.it",
  "student.uni.opole.pl",
  "uniroma2.it",
  "upol.cz",
  "uco.es",
  "kodomo.fbb.msu.ru",
  "alumnos.utalca.cl",
  "uct.cl",
  "gadvasu.in",
  "student.uni-augsburg.de",
  "pharm.uoa.gr",
  "rwth-aachen.de",
  "accademialucchese.it",
  "ceti.mx",
  "ufs.br",
  "chem.tu-berlin.de",
  "alumni.ku.dk",
  "ens-lyon.fr",
  "bio.unibuc.ro",
  "student.kau.in",
  "tcd.ie",
  "alumni.ku.dk",
  "ru.nl",
  "alumnos.utalca.cl",
  "stu.scau.edu.cn",
  "muni.cz",
  "edu.mirea.ru",
  "ubbcluj.ro",
  "tu-dortmund.de",
  "northeastern.edu",
  "ipu.ac.in",
  "unisucrevirtual.edu.co",
  "unica.it",
  "ubi.pt",
  "syphu.edu.cn",
  "estudiantes.uci.cu",
  "stu.scau.edu.cn",
  "students.uni-freiburg.de",
  "uni-miskolc.hu",
  "mail.ioffe.ru",
  "uantwerpen.be",
  "lcc.chem.msu.ru",
  "ki.se",
  "angstrom.uu.se",  
  "upatras.gr",
  "toyo.jp",
  "mail.sdu.edu.cn",
  "student.uoradea.ro",
  "udc.es",
  "mail.zstu.edu.cn",
  "studenti.univpm.it",
  "stu.xzhmu.edu.cn",
  "vub.be",
  "ufscar.br",
  "njucm-edu.cn",
  "uab.cat",
  "uottawa.ca",
  "ulusofona.pt",
  "lpu.in",
  "connect.hku.hk",
  "gncollege.co.in",
  "stu.scau.edu.cn",
  "uit.no",
  "uni-jena.de",
  "student.vu.nl",
  "uni-lj.si",
  "ul.ie",
  "unam.mx",
  "uni-saarland.de",
  "ubc.ca",
  "stu.cpu.edu.cn",
  "sastra.ac.in",
  "namdu.uz",
  "mail.concordia.ca",
  "uc.cl",
  "gncollege.co.in",
  "etu.univh2c.ma",
  "unibs.it",
  "academicos.udg.mx",
  "academico.udg.mx",
  "utalca.cl",
  "unicampania.it",
  "math.uoc.gr",
  "bzh.uni-heidelberg.de",
  "xalapa.tecnm.mx",
  "itmo.ru",
  "uni-bonn.de",
  "zju.edu.cn",
  "vu.nl",
  "academico.ufpb.br",
  "sxcran.org",
  "lagh-univ.dz",
  "tf.uni-kiel.de",
  "jku.at",
  "uni-bremen.de",
  "simtech.uni-stuttgart.de",
  "karazin.ua",
  "rau.am",
  "aluno.uece.br",
  "cnc.uc.pt",
  "xalapa.tecnm.mx",
  "iop.vast.vn",
  "ucm.es",
  "sfu.ca",
  "ua.pt",
  "erauniversity.in",
  "mbg.au.dk",
  "macfast.org",
  "nbu.bg",
  "phys.chem.ethz.ch",
  "ufba.br",
  "uib.no",
  "univ-setif.dz",
  "aalto.fi",
  "sns.it",
  "mail.pucv.cl",
  "postgrad.manchester.ac.uk",
  "polsl.pl",
  "cpc.tu-darmstadt.de",
  "ethz.ch",
  "maastrichtuniversity.nl",
  "dac.unicamp.br",
  "i2bc.paris-saclay.fr",
  "nmhschool.org",
  "iwr.uni-heidelberg.de",
  "jadavpuruniversity.in",
  "univ-saida.dz",
  "utm.my",
  "alunos.fc.ul.pt",
  "bnds.cn",
  "h-brs.de",
  "studenti.unicam.it",
  "uoguelph.ca",
  "aoni.waseda.jp",
  "uio.no",
  "jindai.jp",
  "ki.si",
  "unamur.be",
  "lic.leidenuniv.nl",
  "nmamit.in",
  "ulb.be",
  "unict.it",
  "id.uff.br",
  "riken.jp",
  "unisalento.it",
  "uv.es",
  "alumnos.udg.mx",
  "staff.univpm.it",
  "spbu.ru",
  "ciencias.ulisboa.pt",
  "edu.hse.ru",
  "uni-wuerzburg.de",
  "biophys.msu.ru",
  "pg.cusat.ac.in",
  "chimieparistech.psl.eu",
  "spring8.or.jp",
  "uniba.sk",
  "uni-koeln.de",
  "ufla.br",
  "edu.spbstu.ru",
  "slu.se",
  "polimi.it",
  "bioss.uni-freiburg.de",
  "dulwich.org.uk",
  "unistra.fr",
  "iephb.ru",
  "mail.polimi.it",
  "imperial.ac.uk",
  "uniba.cm",
  "connect.polyu.hk",
  "studenti.unipg.it",
  "uni.lu",
  "uoa.gr",
  "insa-toulouse.fr",
  "samgtu.ru",
  "physik.uni-augsburg.de",
  "neurology.ru",
  "umu.se",
  "uni-graz.at",
  "std.novsu.ru",
  "chemie.uni-regensburg.de",
  "ucdconnect.ie",
  "siat.ac.cn",
  "unesp.br",
  "studenti.polito.it",
  "gmc.vu.lt",
  "tu-berlin.de",
  "bti.vu.lt",
  "kpfu.ru",
  "iau.ir",
  "misis.ru",
  "ufc.br",
  "prof.ce.gov.br",
  "delhi.gov.in",
  "cmail.carleton.ca",
  "theo.chemie.tu-darmstadt.de",
  "uochb.cas.cz",
  "univates.br",
  "bti.vu.lt",
  "my.msu.ru",
  "univ-constantine3.dz",
  "gmc.vu.lt",
  "univie.at",
  "univ-ndere.cm",
  "cunet.carleton.ca",
  "pharma.unizg.hr",
  "sdu.dk",
  "unizar.es",
  "studserv.uni-leipzig.de",
  "alumno.uaemex.mx",
  "rug.nl",
  "uab.es",
  "gu.se",
  "icmpp.ro",
  "uva.nl",
  "estudiantes.uv.mx",
  "sci.am"
];

export default whiteList;
