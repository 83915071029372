import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  Typography,
  Box,
  Grid,
  Paper,
  Button,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  TextField,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { styles } from "../../app";
import { faqData } from "./faq-data";
import { BetaBanner } from "../../components";

const FrequentlyAskedQuestions = () => {
  const navigate = useNavigate();
  const [expandedAll, setExpandedAll] = useState(false);
  const [expanded, setExpanded] = useState(Array(faqData.length).fill(false));
  const [searchQuery, setSearchQuery] = useState("");

  const handleToggleExpandAll = () => {
    setExpandedAll(!expandedAll);
    setExpanded(Array(faqData.length).fill(!expandedAll));
  };

  const handleToggleExpand = (index) => {
    setExpanded((prevExpanded) => {
      const newExpanded = [...prevExpanded];
      newExpanded[index] = !prevExpanded[index];
      return newExpanded;
    });
  };

  // Highlighted text for search box
  const HighlightedText = ({ text, highlight }) => {
    if (!highlight) return <>{text}</>;

    const processText = (text) => {
      if (typeof text === "string") {
        const parts = text.split(new RegExp(`(${highlight})`, "gi"));
        return parts.map((part, index) => (
          part.toLowerCase() === highlight.toLowerCase() ? (
            <span key={index} style={{ backgroundColor: "yellow" }}>
              {part}
            </span>
          ) : (
            <span key={index}>{part}</span>
          )
        ));
      } else if (React.isValidElement(text)) {
        return React.cloneElement(text, {
          children: processText(text.props.children),
        });
      }

      return text;
    };

    return <>{processText(text)}</>;
  };

  // Filter FAQs based on the search query
  const filteredFAQData = faqData.filter((faq) => {
    const questionMatch = faq.question.toLowerCase().includes(searchQuery.toLowerCase());
    const detailsMatch = faq.details.some((detail) =>
      detail.toLowerCase().includes(searchQuery.toLowerCase())
    );
    const answerMatch = faq.answer.some((answerParagraph) => {
      const answerText = Array.isArray(answerParagraph.props.children)
        ? answerParagraph.props.children.join(" ")
        : answerParagraph.props.children;
      return answerText.toLowerCase().includes(searchQuery.toLowerCase());
    });
    return questionMatch || detailsMatch || answerMatch;
  });

  return (
    <div>
    <Grid item xs={12} paddingBottom={5} style={{ display: 'flex', justifyContent: 'center' }}>
        <BetaBanner />
      </Grid>
    <Paper style={{ padding: "20px", width: "1100px", display: "flex", flexDirection: "row", alignItems: "center" }}>
      <Typography padding={4}>
        <Box style={styles.Title}>Frequently Asked Questions</Box>
        <Box style={{ marginBottom: "16px", display: "flex", justifyContent: "space-between", width: "1000px" }}>
          <TextField
            label="Search"
            variant="outlined"
            size="small"
            value={searchQuery}
            onChange={(e) => setSearchQuery(e.target.value)}
          />
          <Button variant="outlined" color="primary" onClick={handleToggleExpandAll}>
            {expandedAll ? "Collapse All" : "Expand All"}
          </Button>
        </Box>
        <Grid container alignItems="center" justifyContent="space-between">
          <Grid item xs={12}>
            {/* Render FAQ entries dynamically using map */}
            {filteredFAQData.map((faq, index) => (
              <Accordion
                key={index}
                expanded={expanded[index]}
                onChange={() => handleToggleExpand(index)}
                sx={{ marginBottom: "16px", boxShadow: "none" }}
              >
                <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                  <Box id={index} paddingRight={3} sx={{ fontWeight: "regular", fontSize: 18 }}>
                    <b>Question: </b>
                    <HighlightedText text={`${faq.question}`} highlight={searchQuery} />
                  </Box>
                </AccordionSummary>
                <AccordionDetails>
                  <div>
                    {faq.details.map((item, i) => (
                      <Box key={i} sx={{ paddingLeft: "40px", fontSize: 16 }}>
                        <HighlightedText text={item} highlight={searchQuery} />
                      </Box>
                    ))}
                    <p>
                      <b>Answer:</b>
                    </p>
                    {faq.answer.map((item, i) => (
                      <Box key={i} sx={{ paddingRight: 3, fontWeight: "regular", fontSize: 18 }}>
                        <HighlightedText text={item} highlight={searchQuery} />
                      </Box>
                    ))}
                  </div>
                </AccordionDetails>
              </Accordion>
            ))}
            <Box style={styles.SubTitle} paddingTop={3}>
              Still Need Help?
            </Box>
            <Button type="submit" variant="contained" color="primary" size="small" onClick={() => navigate("/contact")}>
              CONTACT US
            </Button>
          </Grid>
        </Grid>
      </Typography>
    </Paper>
    </div>
  );
};

export default FrequentlyAskedQuestions;