import {
  LOGIN_REQUEST,
  LOGIN_SUCCESS,
  LOGIN_FAILURE,
  LOGOUT_SUCCESS,
  LOGOUT_FAILURE,
  REGISTER_REQUEST,
  REGISTER_REQUEST_SUCCESS,
  REGISTER_REQUEST_FAILURE,
  REGISTER_SUCCESS,
  REGISTER_FAILURE,
  EMAIL_NOT_VERIFIED,
  PW_RESET_FAILURE,
  PW_RESET_SUCCESS,
  UPDATE_INFO_FAILURE,
  UPDATE_INFO_SUCCESS,
  RESET_AUTH,
  PERSIST_TRUE,
  PERSIST_FALSE,
  RECEIVE_UPDATE_SUCCESS,
  UPDATE_JOB_COUNT,
  UPDATE_FREE_JOBS_COUNT,
} from './auth-types';

import fire from '../../3rd-party-config/fire';
import firebase from 'firebase/compat/app';

import moment from 'moment';
import axios from 'axios';

const createUserWithEmailAndPassword = async (email, password) => {
  return fire.auth().createUserWithEmailAndPassword(email, password);
};

const createPaymentAccount = async (info) => {
  try {
    const res = await axios.post('/payment/create', { 
      "email": info.email,
      "firstName": info.firstName,
      "lastName": info.lastName,
      "country": info.country,
      "userType": info.userType 
    });
    return res.data.stripeId;
  } catch (error) {
    throw new Error(`Payment account creation failed: ${error.message}`);
  }
};

const saveUserInfoToDatabase = async (info, user, stripeId) => {
  try {
    await axios.post('/user-managers/saveUserInfo', {
      email: info.email,
      userId: user.uid,
      firstName: info.firstName,
      lastName: info.lastName,
      country: info.country,
      userType: info.userType,
      jobTitle: info.jobTitle,
      stripeId: stripeId,
      termsConditionsJson: JSON.stringify({
        Signed: moment().format(),
        Version: 1,
      }),
    });
  } catch (err) {
    throw new Error(`Saving user info to database failed: ${err.message}`);
  }
};

export const registerUser = (info) => async (dispatch) => {

  // const warning_msg_reg_failed = "Something went wrong with the registration process. Please contact support@silcsbio.com for additional help."
    var warning_msg_reg_failed = "";
    warning_msg_reg_failed =+ "Something went wrong with the registration process. Please try again by using the 'Forgot Password' link to get a new verification link.";
    warning_msg_reg_failed =+ "If this error persists or you do not receive the verification email, please check the firewall settings associated with your email address provider (e.g., your university IT admin) and your internet service provider.";
    warning_msg_reg_failed =+ "We are using the privacy and security services provided by Google and the verification email needs to have access to Google Services. Using a VPN service to connect through a region where Google Firebase is accessible could help bypass the restriction. ";

  try {
    const userCredential = await createUserWithEmailAndPassword(
      info.email,
      info.password
    );

    const user = await new Promise((resolve) => {
      fire.auth().onAuthStateChanged((user) => resolve(user));
    });

    const stripeId = await createPaymentAccount(info);

    await saveUserInfoToDatabase(info, user, stripeId);

    user.sendEmailVerification({
      url: 'https://app.cgenff.com/login',
    });

    dispatch({
      type: REGISTER_REQUEST_SUCCESS,
      payload: {
        message:
          `Account was successfully created! Check your inbox to verify your e-mail address. If you haven't received the verification email within 24 hours, please check the firewall settings with your email address provider (your university IT admin) and your internet service provider. You can also click on "Reset Password" to request another verification email.`,
        textColor: 'green',
      },
    });

    fire.auth().onAuthStateChanged((user) => {
      if (user.emailVerified) {
        dispatch({
          type: REGISTER_SUCCESS,
          payload: {
            message: 'E-mail address verified.',
            textColor: 'green',
          },
        });
      }
    });
  } catch (err) {
    if (err.code === 'auth/email-already-in-use') {
      dispatch({
        type: REGISTER_FAILURE,
        payload: {
          message: `The email address is already in use by another account.`,
          textColor: 'red',
        },
      });
    } else {
      dispatch({
        type: REGISTER_FAILURE,
        payload: {
          message: warning_msg_reg_failed,
          textColor: "red"
        }
      })
    }
  }
};


//User Login
export const loginUser = (email, password) => async (dispatch) => {
  try {
    await fire.auth().setPersistence(firebase.auth.Auth.Persistence.SESSION);

    const userCredential = await fire.auth().signInWithEmailAndPassword(email, password);
    const user = userCredential.user;

    if (user.emailVerified) {
      const userData = await axios.post('/user-managers/getUserData', { userId: user.uid });

      if (userData.data.Email_Verified === 0) {
        await axios.post('/user-managers/updateUserInfo', {
          userId: user.uid,
          fieldsToUpdate: { Email_Verified: 1 },
        });
      }

      dispatch({
        type: LOGIN_SUCCESS,
        payload: {
          userId: user.uid,
          userEmail: user.email,
          stripeId: userData.data.Stripe_ID,
          firstName: userData.data.First_Name,
          lastName: userData.data.Last_Name,
          company: userData.data.Group_Name,
          userType: userData.data.User_Type,
          jobCount: userData.data.Job_Counts,
          freeCount: userData.data.Free_Jobs,
          receiveUpdates: userData.data.Receive_Updates,
        },
      });
    } else {
      dispatch({
        type: EMAIL_NOT_VERIFIED,
        payload: {
          message: "You haven't verified your e-mail address.",
          textColor: "red",
        },
      });
    }
  } catch (err) {
    if (err.code === 'auth/user-not-found') {
      dispatch({
        type: LOGIN_FAILURE,
        payload: {
          message:
            "There is no user record corresponding to this email address. If this is a mistake, please contact us at support@silcsbio.com",
          textColor: "red",
        },
      });
    } else if (err.code === 'auth/invalid-credential') {
      dispatch({
        type: LOGIN_FAILURE,
        payload: {
          message:
            "Invalid log in credential. Please double check your email and password. You can reset your password by clicking on the Forgot Password link below.",
          textColor: "red",
        },
      });
    
    } else if (err.code === 'auth/network-request-failed') {
      dispatch({
        type: LOGIN_FAILURE,
        payload: {
          message:
            "Your network is unstable. Please try again later",
          textColor: "red",
        },
      });
    } else {
      dispatch({
        type: LOGIN_FAILURE,
        payload: {
          message: err.message,
          textColor: "red",
        },
      });
    }
  }
};


// persist
export const persist = () => async (dispatch) => {
  try {
    fire.auth().onAuthStateChanged(async (user) => {
      if (user) {
        if (user.emailVerified) {
          dispatch({
            type: PERSIST_TRUE,
          });
        } else {
          dispatch({
            type: PERSIST_FALSE,
          });
        }
      } else {
        dispatch({
          type: PERSIST_FALSE,
        });
      }
    });
  } catch (err) {
    dispatch({
      type: PERSIST_FALSE,
      payload: {
        message: `Persist operation failed: ${err.message}`,
        textColor: "red",
      },
    });
  }
};


// logout
export const logoutUser = () => async dispatch => {
  try {
    await fire
      .auth()
      .signOut()
      .then(() => {
        dispatch({ type: LOGOUT_SUCCESS });
      })
      .catch(err => {
        dispatch({
          type: LOGOUT_FAILURE,
          payload: {
            message: err.message,
            textColor: "red"
          }
        });
      });
  } catch (err) {
    dispatch({
      type: LOGOUT_FAILURE,
      payload: {
        message: err.message,
        textColor: "red"
      }
    });
  }
}

// Reset password with Firebase
export const resetPassword = (email) => async dispatch => {
  try {
    await fire
      .auth()
      .sendPasswordResetEmail(email)
      .then(() =>
        dispatch({
          type: PW_RESET_SUCCESS,
          payload: {
            message: "You'll receive a reset password email if there is an account associated with the email address you've entered",
            textColor: "green"
          }
        })
      )
  } catch (err) {
    dispatch({
      type: PW_RESET_FAILURE,
      payload: {
        message: err.message,
        textColor: "red"
      }
    });
  }
};

export const updateUserInfo = (firstName, lastName, company) => async dispatch => {
  try {
    dispatch({
      type: UPDATE_INFO_SUCCESS,
      payload: {
        firstName: firstName,
        lastName: lastName,
        company: company,
      }
    });
  } catch (err) {
    dispatch({
      type: UPDATE_INFO_FAILURE
    })
  }
}

export const updateCount = (jobCount) => {
  return {
    type: UPDATE_JOB_COUNT,
    payload: {jobCount: jobCount}
  }
}

export const updateFreeCount = (freeCount) => {
  return {
    type: UPDATE_FREE_JOBS_COUNT,
    payload: {freeCount: freeCount}
  }
}

export const saveReceiveUpdate = (receiveUpdates) => async dispatch => {
	dispatch({
		type: RECEIVE_UPDATE_SUCCESS,
		payload: {
			receiveUpdates: receiveUpdates,
		}
	});
}

export const resetAuth = () => {
  return {
    type: RESET_AUTH
  }
}

export const loginRequest = () => {
  return {
    type: LOGIN_REQUEST
  }
}

export const registerRequest = () => {
  return {
    type: REGISTER_REQUEST
  }
}
