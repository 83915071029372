import React, { useState, useEffect } from "react";
import axios from 'axios';
import Parser from 'html-react-parser';

const DisplayFile = ({ inFile }) => {
  const [file, setFile] = useState('');

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(inFile);
        setFile(response.data);
      } catch (error) {
        console.error('Error fetching file:', error);
      }
    };

    fetchData();
  }, [inFile]);

  const styles = {
    maxHeight: "70vh",
    overflowY: "scroll",
    scrollBehavior: "smooth",
    lineHeight: '1.1',
    fontSize: '16px'
  };

  return (
    <div style={styles}>
      <pre>{Parser(file)}</pre>
    </div>
  );
};

export default DisplayFile;
