import React, { useState } from "react";
import { connect } from "react-redux";

import { useNavigate } from "react-router-dom";
import { makeStyles } from "@mui/styles"
import { Grid, Typography, Paper, Button } from "@mui/material";

import { UploadIcon, ViewIcon, CheckoutIcon } from "../../media";
import { styles } from "../../app";

import { UploadMol2 } from "./utils";
import { saveMol2FileName, saveCenter, resetJob } from "../../redux/job/job-actions";
import { TutorialCarousel, tutorialStepsIndustry, tutorialStepsAcademia } from "../user-manual/utils";
import { BetaBanner } from "../../components";
const useStyles = makeStyles(() => ({
  image: {
    display: 'flex',
    justifyContent: 'center',
  }
}));

const StartCgenffJob = ({ userId, userType, jobCount, saveMol2FileName, saveCenter, resetJob }) => {

  const classes = useStyles();
  const navigate = useNavigate()

  const iconSize = '120px'

  const handleClick = () => {
    navigate('/input')
  }

  const [open, setOpen] = useState(false);
  const [openTutorial, setOpenTutorial] = useState(false)

  let tutorialSteps;
  if (userType==='Academia') {
    tutorialSteps = tutorialStepsAcademia
  } else {
    tutorialSteps = tutorialStepsIndustry
  }


  return (
<div>
    <Grid item xs={12} paddingBottom={5} style={{ display: 'flex', justifyContent: 'center' }}>
        <BetaBanner />
      </Grid>

    <Paper style={{
      padding: '40px', minWidth: "900px", maxWidth: "1100px",
      display: 'flex',
      justifyContent: 'center'
    }}>

      <Grid container spacing={2}
        style={{ width: '680px' }}>

        <Grid item xs={12} paddingTop={5} paddingBottom={5}
          style={{
            display: 'flex',
            placeContent: 'center'
          }} >
          <Button
            size="large"
            variant="contained"
            color="primary"
            style={{ width: '200pt', height: '45pt', fontSize: '30px', fontWeight: 'bold' }}
            onClick={() => { setOpen(true); resetJob() }}
          >
            RUN CGenFF
          </Button>
        </Grid>

        <Grid item container xs={12} padding={2} columnSpacing={5}>
          <Grid item xs={3}>
            <div className={classes.image} onClick={handleClick}>
              <img alt="" src={UploadIcon} width={iconSize} />
            </div>
          </Grid>
          <Grid item xs={9} style={{
            display: 'flex',
            flexDirection: 'column',
            placeContent: 'center'
          }}>
            <Typography align="left" fontWeight='bold'>
              Step 1: Upload a Mol2 file of your molecule
            </Typography>
            <Typography align="left">
              Please make sure the Mol2 file name does not contain special characters.
            </Typography>
          </Grid>

        </Grid>

        <Grid item container xs={12} padding={2} columnSpacing={5}>
          <Grid item xs={3}>
            <div className={classes.image} onClick={handleClick}>
              <img alt="" src={ViewIcon} width={iconSize} />
            </div>
          </Grid>
          <Grid item xs={9} style={{
            display: 'flex',
            flexDirection: 'column',
            placeContent: 'center'
          }}>
            <Typography align="left" fontWeight='bold'>
              Step 2: Visualize the molecule and select options
            </Typography>
            <Typography align="left">
              Double-check if the correct molecule has been uploaded and all the selected options are correct.
            </Typography>
          </Grid>

        </Grid>

        <Grid item container xs={12} padding={2} columnSpacing={5}>
          <Grid item xs={3}>
            <div className={classes.image} onClick={handleClick}>
              <img alt="" src={CheckoutIcon} width={iconSize} />
            </div>
          </Grid>
          <Grid item xs={9} style={{
            display: 'flex',
            flexDirection: 'column',
            placeContent: 'center'
          }}>
            <Typography align="left" fontWeight='bold'>
              { userType === 'Industry' ? "Step 3: Pay and get molecule parameters" :
               "Step 3: Run and download molecule parameters"
              }
            </Typography>

            
            <Typography align="left">
            {userType=== 'Industry' ? 
              "You can pay for the job with a valid credit card or US bank account. After payment, your molecule’s parameters will be available for download. Your results will also be available under “Job History” on the “Home” page." 
              : "Run and download the molecule parameters. The results are also available under Job History."}
              </Typography>

          </Grid>

        </Grid>

        <Grid item xs={12} style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', placeContent: 'center' }}>
          <Typography style={styles.Title}>
            Need Help?
          </Typography>

          <Button
            size="large"
            variant="contained"
            color="primary"
            onClick={() => { setOpenTutorial(true) }}
          >
            Start Tutorial
          </Button>
        </Grid>

      </Grid>

      <UploadMol2
        userId={userId}
        userType={userType}
        jobCount={jobCount}
        open={open}
        setOpen={setOpen}
        saveMol2FileName={saveMol2FileName}
        saveCenter={saveCenter}
      />

      <TutorialCarousel
        open={openTutorial}
        setOpen={setOpenTutorial}
        tutorialSteps={tutorialSteps}
      />

    </Paper>
    </div>
  )
}

const mapStateToProps = (state) => {
  return {
    userId: state.auth.user.userId,
    userType: state.auth.user.userType,
    jobCount: state.auth.user.jobCount,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    saveMol2FileName: (fileName, jobId) => dispatch(saveMol2FileName(fileName, jobId)),
    saveCenter: (com) => dispatch(saveCenter(com)),
    resetJob: () => dispatch(resetJob()),
  };
};


export default connect(mapStateToProps, mapDispatchToProps)(StartCgenffJob);