import React from 'react';

import { PrivateRoute, PrivateCGenFFRoute, PrivateAcademiaRoute } from './utils';

import { Login, Signup, Logout } from '../pages/authentication';
import { Homepage } from '../pages/homepage';
import { StartCgenffJob, Options, Checkout } from '../pages/start-cgenff-job';
import { Sidebar } from '../app/utils';
import { UserManual } from '../pages/user-manual';
import { UserSettings } from '../pages/user-settings';
import { FrequentlyAskedQuestions } from '../pages/faq';
import { PricingInfo } from '../pages/pricing-info';
import { ContactUs } from '../pages/contact-us';
import { Feedback } from '../pages/feedback'
import { JobHistoryTable } from '../pages/job-history';
import { styles } from '../app';

import {
  createBrowserRouter,
  createRoutesFromElements,
  Route,
  Outlet,
  Navigate,
} from "react-router-dom";

import { connect } from 'react-redux';
import { resetJob } from '../redux/job/job-actions';
import { logoutUser } from '../redux/auth/auth-actions';


const Layout = connect(
  state => ({
    userId: state.auth.user.userId,
    userType: state.auth.user.userType,
  }),
  dispatch => ({
    resetJob: () => dispatch(resetJob()),
    logoutUser: () => dispatch(logoutUser())
  })
)(({ userId, resetJob, logoutUser, userType }) => {

  return (
    <div style={styles.Root}>
      <Sidebar userId={userId} userType={userType} resetJob={resetJob} logoutUser={logoutUser}/>
      <main style={styles.Main}>
        <Outlet />
      </main>
    </div>
  )
})

const WithoutSideBar = () => {
  return (
    <main style={styles.MainWOSideBar}>
      <Outlet />
    </main>
  )
}


const AppRouter = createBrowserRouter(

  createRoutesFromElements(

    <Route>
      <Route element={<WithoutSideBar />}>
        <Route exact path="/login" element={<Login />} />
        <Route exact path="/signup" element={<Signup />} />
        <Route exact path="/logout" element={<Logout />} />
      </Route>
      <Route element={<Layout />}>
        <Route element={<PrivateRoute />}>
          <Route exact path="/homepage" element={<Homepage />} />

          <Route exact path="/input" element={<StartCgenffJob />} />
          
          <Route element={<PrivateCGenFFRoute />}>
            <Route exact path="/options" element={<Options />} />
            <Route exact path="/checkout" element={<Checkout />} />
          </Route>

          <Route exact path="/history" element={<JobHistoryTable />} />
          <Route exact path="/faq" element={<FrequentlyAskedQuestions />} />

          <Route element={<PrivateAcademiaRoute />}>
            <Route exact path="/pricing" element={<PricingInfo />} />
          </Route>

          <Route exact path="/manual" element={<UserManual />} />
          <Route exact path="/settings" element={<UserSettings />} />
          <Route exact path="/contact" element={<ContactUs />} />
          <Route exact path="/feedback" element={<Feedback />} />

        </Route>

        <Route path="*" element={<Navigate to="/homepage" />} />

      </Route>
    </Route>

  )
);

export default (AppRouter);