import React, { useEffect, useState } from "react";
import axios from "axios";
import { saveAs } from 'file-saver';
import { connect } from "react-redux";
import { Tab, TabList, TabPanel, Tabs } from 'react-tabs';
import { Box, Button, Grid, Tooltip, Typography } from "@mui/material";
import { makeStyles } from '@mui/styles';
import BrowserUpdatedOutlinedIcon from '@mui/icons-material/BrowserUpdatedOutlined';

import 'react-tabs/style/react-tabs.scss';
import { Visualizer, CheckoutPopup, DisplayFile, JobInfo } from './utils';
import { updateCount, updateFreeCount } from "../../redux/auth/auth-actions";
import { BetaBanner } from "../../components";
import { LoadingDna } from "../../components";

const useStyles = makeStyles({
  checkout: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    paddingBottom: "20px",
  },
});


const CGenFFCheckout = ({
  userId,
  stripeId,
  userEmail,
  userType,
  freeCount, 
  jobId,
  fileName,
  jobName,
  stem,
  jobOptions,
  jobFailed,
  centerx,
  centery,
  centerz,
  updateCount,
  updateFreeCount,
}) => {
  const classes = useStyles();
  const [open, setOpen] = useState(false);
  const [isPaid, setIsPaid] = useState(false);
  const [message, setMessage] = useState(['Please double-check the job info before proceeding'])
  const [paramPenaltyScore, setParamPenaltyScore] = useState("N/A");
  const [chargePenaltyScore, setChargePenaltyScore] = useState("N/A");
  const [isLoading, setIsLoading] = useState(false)
  const [textColor, setTextColor] = useState('green')
  const [converted, setConverted] = useState(false)
  const [ranGromacs, setRanGromacs] = useState(false)
  const FILE_PATHS = {
    mol2: `./build/${userId}/${jobId}/${stem}.mol2`,
    str: `./build/${userId}/${jobId}/${stem}.str`,
    log: `./build/${userId}/${jobId}/${stem}.err`,
    score: `./build/${userId}/${jobId}/score.txt`
  };

  const convertToGromacs = () => {
    setIsLoading(true)
    axios.post('/job-managers/submitGromacs', {
      'userId': userId,
      'jobId': jobId,
      'fileName': fileName,
      'stem': stem,
    })
    .then((res) => {
      if (res.status === 200) {
        setMessage('Successfully converted to Gromacs format')
        setConverted(true)
        setIsLoading(false)
        setTextColor('green')
      }
    })
    .catch((err) => {
      if (err.response.data === 'GRMX') {
        if (userType === 'Academia') {
          setMessage('The mol2 file that was used cannot be converted to Gromacs format')
        } else {
          setMessage('The mol2 file that was used cannot be converted to Gromacs format. Please contact us at support@silcsbio.com if you need additional help.')
        }
        setRanGromacs(true)
        setIsLoading(false)
        setTextColor('red')
      }
    })
  }

  const getPenaltyScore = async () => {
    try {
      const response = await axios.get(FILE_PATHS.score);
      const text = response.data;

      const paramMatch = text.match(/(! param penalty=( )+([+-]?(\d*\.)?\d+) ;)/);
      const chargeMatch = text.match(/(; charge penalty=( )+([+-]?(\d*\.)?\d+))/);

      const parsedParamPenaltyScore = paramMatch ? paramMatch[0].match(/([+-]?(\d*\.)?\d+)/)[0] : null;
      const parsedChargePenaltyScore = chargeMatch ? chargeMatch[0].match(/([+-]?(\d*\.)?\d+)/)[0] : null;

      setParamPenaltyScore(parsedParamPenaltyScore);
      setChargePenaltyScore(parsedChargePenaltyScore);
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };

  const downloadResults = async () => {
    try {
      const response = await axios.get(`/build/${userId}/${jobId}/result.zip`, { responseType: 'blob' });
      saveAs(new Blob([response.data], { type: response.headers['content-type'] }), `${stem}.zip`);
    } catch (error) {
      console.error('Error downloading results:', error);
    }
  };

  const downloadFile = async (file) => {
    try {
      const fileName = file.split('/').pop();
      const response = await axios.get(file, { responseType: 'blob' });

      saveAs(new Blob([response.data], { type: response.headers['content-type'] }), fileName);
    } catch (error) {
      console.error('Error downloading file:', error);
    }
  };

  const downloadOrDeleteResults = () => {
    axios
      .post('/jobs/file-management/s3ResultsDownloadOrDelete', {
        'Output_S3_Location': `${userId}/${jobId}/${stem}_gromacs.zip`,
        'userId': userId,
        'jobId': jobId,
        'action': 'download'
      })
      .then((res) =>  {saveAs(res.data)} )
      .catch((err) => console.log(err))
  }

  const uploadResults = async () => {
    try {
      const zipResultsResponse = await axios.post('/jobs/file-management/zipResults', {
        userId,
        jobId,
        stem,
      });

      if (zipResultsResponse.status === 200) {
        await axios.post('/jobs/file-management/s3ResultsUpload', {
          userId,
          jobId,
          stem,
        }).catch((err) => console.error(err));
      }

      await axios.post('/jobs/saveJobInfo2db', {
        userId,
        jobId,
        jobName,
        fileName,
        jobOptionsJson: jobOptions,
        outputS3Location: `${userId}/${jobId}/${stem}.zip`,
      }).catch((err) => console.error(err));

    } catch (error) {
      console.error('Error uploading results:', error);
    }
  };

  const updateJobCounts = async () => {
    try {
      const response = await axios.post('/jobs/updateJobCounts', { userId });
      updateCount(response.data.updatedCount);
    } catch (error) {
      console.error('Error updating job counts:', error);
    }
  };

  const updateFreeJobsCounts = async () => {
    try {
      const response = await axios.post('/jobs/updateFreeJobsCounts', { userId });
      updateFreeCount(response.data.updatedCount);
    } catch (error) {
      console.error('Error updating job counts:', error);
    }
  };

  const checkout = async () => {
    if (userType === 'Industry' && freeCount <= 0) {
      setOpen(true);
      setIsPaid(false);
    } else if (userType === 'Academia' || freeCount > 0) {
      try {
        setIsPaid(true);
        await updateJobCounts();
        if (freeCount > 0) {
          await updateFreeJobsCounts();
        }
        await uploadResults();
      } catch (error) {
        console.error('Error during checkout:', error);
      }
    }
  };

  useEffect(() => {
    if (isPaid) {
      setMessage([`CGenFF run was successful. The result is ready for downloading`])
      setTextColor('green')
    }
  }, [isPaid])

  useEffect(() => {
    if (jobFailed) {
      setMessage([`The uploaded Mol2 file cannot be processed by the CGenFF engine. Please try a different molecule. See the log file for more information`])
      setTextColor('red')
    } else {
      getPenaltyScore()
    }
  }, [jobFailed]);

  return (
    <div className={classes.checkout}>

      {/* <FormPrompt isPaid={isPaid} jobFailed={jobFailed} /> */}
      <Grid item xs={12} paddingBottom={5} style={{ display: 'flex', justifyContent: 'center' }}>
        <BetaBanner />
      </Grid>

      <Typography style={{ margin: 1, color: textColor }}> {message} </Typography>


      <Grid>
        {isLoading &&
            <LoadingDna size='60' />
        }
        <Button
          onClick={checkout}
          variant="contained"
          color="primary"
          style={{ margin: 20 }}
          disabled={isPaid || jobFailed}
        >
          {userType === 'Academia' || freeCount > 0 ? `RUN CGENFF ENGINE` : `PAY & RUN CGENFF ENGINE`}
        </Button>

        <Button
          variant="contained"
          color="primary"
          style={{ margin: 20 }}
          disabled={!isPaid}
          onClick={downloadResults}
        >
          DOWNLOAD CGENFF RESULTS
        </Button>

        {(isPaid && !converted) && (
            <Button
              onClick={convertToGromacs}
              disabled={ranGromacs || isLoading}
              variant="contained"
              color="primary"
              style={{ margin: 20 }}
            >
              CONVERT TO GROMACS
            </Button>
        )}

        { (isPaid && converted) && (
          <Button
            onClick={downloadOrDeleteResults}
            variant="contained"
            color="primary"
            style={{ margin: 20 }}
          >
            DOWNLOAD GROMACS FORMAT
          </Button>
        )}
      </Grid>

      <Box sx={{ width: '1200px', background: 'white' }}>
        <Grid container>
          <Grid item xs={5.5} padding={2}>
            <Visualizer
              width={'500px'}
              height={'70vh'}
              path={FILE_PATHS.mol2}
              bgcolor="#deeafc"
              stem={stem}
              position={[centerx, centery, centerz]}
            />
          </Grid>

          <Grid item xs={6.5}>
            <Tabs defaultIndex={0} forceRenderTabPanel={true}>
              <TabList>
                {isPaid && (
                  <Tab>
                    <Typography style={{ fontSize: '15px' }}>
                      <Tooltip title="Download File">
                        <BrowserUpdatedOutlinedIcon onClick={() => { downloadFile(FILE_PATHS.str) }} color='primary' fontSize="xsmall" />
                      </Tooltip>
                      Result Stream File
                    </Typography>
                  </Tab>
                )}

                {(isPaid || jobFailed) && (
                  <Tab>
                    <Typography style={{ fontSize: '15px' }}>
                      <Tooltip title="Download File">
                        <BrowserUpdatedOutlinedIcon onClick={() => { downloadFile(FILE_PATHS.log) }} color='primary' fontSize="xsmall" />
                      </Tooltip>
                      Log File
                    </Typography>
                  </Tab>
                )}

                <Tab><Typography style={{ fontSize: '15px' }}>CGenFF Job Info</Typography></Tab>
                <Tab><Typography style={{ fontSize: '15px' }}>Mol2 File</Typography></Tab>
              </TabList>

              {isPaid && (
                <TabPanel>
                  <DisplayFile inFile={FILE_PATHS.str} />
                </TabPanel>
              )}

              {(isPaid || jobFailed) && (
                <TabPanel>
                  <DisplayFile inFile={FILE_PATHS.log} />
                </TabPanel>
              )}

              <TabPanel>
                <JobInfo
                  stem={stem}
                  fileName={fileName}
                  jobName={jobName}
                  jobOptions={jobOptions}
                  paramPenaltyScore={paramPenaltyScore}
                  chargePenaltyScore={chargePenaltyScore}
                  jobFailed={jobFailed}
                  userType={userType}
                />
              </TabPanel>

              <TabPanel>
                <DisplayFile inFile={FILE_PATHS.mol2} />
              </TabPanel>
            </Tabs>
          </Grid>
        </Grid>
      </Box>

      <CheckoutPopup
        userId={userId}
        jobId={jobId}
        stripeId={stripeId}
        userEmail={userEmail}
        open={open}
        setOpen={setOpen}
        setIsPaid={setIsPaid}
        uploadResult={uploadResults}
        updateJobCounts={updateJobCounts}
      />

    </div>
  );
}

const mapStateToProps = (state) => {
  return {
    userId: state.auth.user.userId,
    userEmail: state.auth.user.userEmail,
    userType: state.auth.user.userType,
    freeCount: state.auth.user.freeCount,
    stripeId: state.auth.user.stripeId,
    jobId: state.job.jobInfo.jobId,
    fileName: state.job.jobInfo.fileName,
    jobName: state.job.jobInfo.jobName,
    stem: state.job.jobInfo.stem,
    jobOptions: state.job.jobOptions,
    jobFailed: state.job.jobFailed,
    centerx: state.job.jobInfo.centerx,
    centery: state.job.jobInfo.centery,
    centerz: state.job.jobInfo.centerz
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    updateCount: (count) => dispatch(updateCount(count)),
    updateFreeCount: (count) => dispatch(updateFreeCount(count))
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(CGenFFCheckout);
