import React, { useState } from "react";
import { connect } from "react-redux";
import {
  Alert,
  Typography,
  TextField,
  Grid,
  Paper,
  Button,
  InputLabel,
  Snackbar,
  IconButton,
} from "@mui/material";

import isEmail from "validator/lib/isEmail";
import axios from "axios";

import PhoneIcon from '@mui/icons-material/Phone';
import EmailIcon from '@mui/icons-material/Email';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import MarkEmailReadOutlinedIcon from "@mui/icons-material/MarkEmailReadOutlined";

import { styles } from "../../app";
import { BetaBanner } from "../../components";

const ContactUs = ({ userEmail, firstName, lastName }) => {
  const [senderEmail, setSenderEmail] = useState(userEmail);
  const [senderName, setSenderName] = useState(`${firstName} ${lastName}`);
  const [subject, setSubject] = useState('');
  const [message, setMessage] = useState('');
  const [errors, setErrors] = useState('');
  const [showAlert, setShowAlert] = useState(false);
  const [count, setCount] = useState(0);

  const handleClose = () => setShowAlert(false);

  const resetForm = () => {
    setSubject('');
    setMessage('');
  };

  const handleClick = () => {
    if (!isEmail(senderEmail)) {
      setErrors("Email address is invalid");
    } else if (!senderEmail || !subject || !senderName || !message) {
      setErrors("Please fill in all the information");
    } else {
      setErrors('');
      axios.post('/emailFromWebApp', {
        senderEmail,
        senderIdentity: senderName,
        subject,
        message,
      }).then(() => {
        setShowAlert(true);
        resetForm();
      });
    }
  };

  return (
    <div>
      <Grid item xs={12} paddingBottom={5} style={{ display: 'flex', justifyContent: 'center' }}>
        <BetaBanner />
      </Grid>
      <Paper style={{ padding: '40px', minWidth: "900", maxWidth: "1100px", display: 'flex', flexDirection: 'column', alignItems: "center" }}>
        <Typography padding={4}>
          <Grid container style={{ minWidth: "900px", maxWidth: "1100px" }}>

            <Grid item xs={5}>
              <Grid item style={styles.Title} paddingBottom={5}>
                GET IN TOUCH
              </Grid>

              {[
                { icon: <PhoneIcon />, text: "+1 410-754-4297 ext. 717", action: () => window.location = "tel:+1 410-754-4297,7175" },
                { icon: <EmailIcon />, text: "support@silcsbio.com", action: () => window.location = "mailto:support@silcsbio.com" },
                { icon: <LinkedInIcon />, text: "Follow us on LinkedIn", action: () => window.open("https://www.linkedin.com/company/silcsbio/", "_blank") },
              ].map(({ icon, text, action }, index) => (
                <Grid key={index} item container columnSpacing={1} alignItems='center'>
                  <Grid item>
                    <IconButton color="primary" onClick={action}>
                      {icon}
                    </IconButton>
                  </Grid>
                  <Grid item>
                    {text}
                  </Grid>
                </Grid>
              ))}

            </Grid>

            <Grid item xs={7}>
              <Grid item style={styles.Title} paddingBottom={5}>
                HOW CAN WE HELP?
              </Grid>

              {[
                { label: "Your name", value: senderName, maxLength: 50, onChange: e => setSenderName(e.target.value) },
                { label: "Your email", value: senderEmail, maxLength: 50, onChange: e => setSenderEmail(e.target.value) },
                { label: "Subject", value: subject, maxLength: 100, onChange: e => setSubject(e.target.value) },
                { label: "Message", value: message, maxLength: 1000, onChange: e => { setMessage(e.target.value); setCount(e.target.value.length) }, multiline: true },
              ].map(({ label, value, maxLength, onChange, multiline }, index) => (
                <Grid key={index} item xs={12} paddingBottom={index === 3 ? 4 : 0}>
                  <InputLabel shrink={false} htmlFor={label.toLowerCase().replace(/\s+/g, "")}>
                    <Typography>{label}</Typography>
                  </InputLabel>
                  <TextField
                    required
                    id={label.toLowerCase().replace(/\s+/g, "")}
                    fullWidth
                    margin="normal"
                    name={label.toLowerCase().replace(/\s+/g, "")}
                    autoComplete={label.toLowerCase().replace(/\s+/g, "")}
                    value={value}
                    variant="outlined"
                    inputProps={{ maxLength: maxLength }}
                    onChange={onChange}
                    multiline={multiline}
                  />
                  {index === 3 && <Typography align="right" fontSize={12}>{count}/1000 characters</Typography>}
                </Grid>
              ))}

              <Grid paddingBottom={2}>
                <Typography color='error'>{errors}</Typography>
              </Grid>

              <Button
                type="submit"
                variant="contained"
                color="primary"
                size='small'
                onClick={handleClick}
              >
                CONTACT US
              </Button>

              <Snackbar
                autoHideDuration={5000}
                anchorOrigin={{ vertical: "top", horizontal: "center" }}
                open={showAlert}
                onClose={handleClose}
              >
                <Alert onClose={handleClose} severity="success" icon={<MarkEmailReadOutlinedIcon />}>
                  Message successfully sent. We will get back to you shortly!
                </Alert>
              </Snackbar>

            </Grid>
          </Grid>
        </Typography>
      </Paper>
    </div>
  );
};

const mapStateToProps = (state) => ({
  userEmail: state.auth.user.userEmail,
  firstName: state.auth.user.firstName,
  lastName: state.auth.user.lastName,
});

export default connect(mapStateToProps)(ContactUs);
