export const faqData = [
  {
    question: "I received one of these warnings from the log files. What do they mean?",
    details: [
      "- more 'ne' statements than neighbors",
      "- sp-hybridized aromatic atom",
      "- carbon radical, carbocation or carbanion not supported",
      "- amide base not supported",
      "- =[N+]= not supported",
    ],
    answer: [
      <p>
        These warnings mean that the connectivity of the molecule is not supported by the atom typing rules.
        These errors are most often encountered when submitting a molecule with missing hydrogens.
        Other possible causes include unfulfilled valences and inconsistent bond orders.
        A very common origin of these defects is the conversion from file formats that don't contain connectivity information (such as pdb) to Mol2.
        OpenBabel assigns bond orders based on the proximity between atoms, which is an inherently imprecise process, especially when starting from a distorted structure.
        We therefore only allow molecule file in Mol2 format that contains bond orders, such as Mol2; tips on how to generate valid Mol2 files can be found in this FAQ entry.
      </p>,
      <p>
        If the problem occurs despite the molecule being uploaded in Mol2 format and you are unsure about how to correct it, it is advisable to ask for help from a chemist.
        If the problem persists, please contact us. Remember, atom typing is almost never correct when these warnings are encountered!
      </p>
    ],
  },
  {
    question: "What do readmol2 warnings mean?",
    details: [""],
    answer: [
      <p>
        Readmol2 warnings point to technical issues with the input Mol2 file.
        Please see the answers to the <a href="#2">this entry</a> and <a href="#3">this entry</a> for more information.
      </p>
    ],
  },
  {
    question: "What does the “unknown bond type” warning mean?",
    details: [""],
    answer: [
      <p>
        Readmol2 warnings point to technical issues with the input Mol2 file.
        If followed by the phrase "skipped molecule" (as in the case of "unknown bond type"), the technical issue prohibits the generation of an output str file.
        If not, the issue may be as trivial as the presence of fields in the Mol2 file that are not part of the Mol2 specification, which would be safely ignored.
      </p>
    ],
  },
  {
    question: "Then how do I create a valid Mol2 file?",
    details: [""],
    answer: [
      <p>
        Sadly, many popular molecular viewers (e.g., VMD, pymol, etc.) may not generate valid Mol2 files.
      </p>,
      <p>
        The Avogadro molecular editor is an open-source program that allows the user to visualize and edit bond orders and save the result as a valid Mol2 file that is conforms to the Mol2 standard.
        It does not write out a molecule name or atom numbering, but these can readily be added by the user by modifying the Mol2 file in a text editor (the ***** on the second line is a placeholder for the molecule name).
      </p>,
      <p>
        Mol2 files from the Zinc database are generally high-quality.
      </p>,
      <p>
        Here are two free closed-source programs that do the job:
        <ul>
          <li>BIOVIA Discovery Studio Visualizer</li>
          <li>Schrödinger's Maestro (free for academia only through the Maestro Academic Campaign)</li>
        </ul>
      </p>,
      <p>
        Here are some commercial programs (other than the paid versions of the above):
        <ul>
          <li>Chemical Computing Group's MOE</li>
          <li>Tripos' Sybyl</li>
          <li>Wavefunction's Spartan</li>
          <li>Semichem's AGUI (part of the AMPAC suite) after sed -i 's/Ar/ar/' *.mol2</li>
          <li>Gaussian's GaussView after sed -i 's/Ar/ar/' *.mol2</li>
          <li>Hypercube's HyperChem after sed -i 's/_\+ //' *.mol2</li>
        </ul>
        Please contact us if you think additions should be made to these lists! In particular, it would be nice to know of more open-source programs that have the same abilities.
      </p>,
    ],
  },
  {
    question: "My molecule was given relatively high penalties, suggesting I should perform validation and/or optimization. How should I go about doing this?",
    details: [""],
    answer: [
      <p id="4">
        Validation strategies are in general decided on a case-by-case basis.
        To determine the most optimal validation approach, some understanding of the force field parametrization procedure is required.
        CGenFF is mostly optimized targeting QM data, as explained in the 2010 CGenFF paper, 2019 FFParam paper and in the FFParam and CGenFF tutorials.
        See details on using <a href="https://ffparam.silcsbio.com" target="_blank" rel="noreferrer">FFParam</a> for performing parameter optimization.
      </p>,
      <p>
        After QM-based parameter optimization, it is common to validate the resulting force field against experimental data if available, such as hydration free energies and pure solvent or crystal properties, including intramolecular geometries, and fine-tune it if necessary.
        This is important because the force field ultimately is expected to reproduce bulk phase experimental properties and not gas-phase QM data.
        However, one cannot entirely optimize a force field based on experimental data because this would require a very large investment of time and computer power and a number of target data points that are generally unavailable for all but the simplest molecules.
        Indeed, in most practical cases, the few available experimental data points do not correlate optimally with the (high penalty) parameters to be optimized.
        For instance, the energetics of binding and solvation correlate with the charge distribution but often not with individual charges.
        Similarly, solution phases conformational preferences (as typically measured by NMR experiments) can be used to detect problematic dihedral parameters, but are not always straightforward to use for optimizing these dihedrals.
      </p>,
      <p>
        The standard recommendation for end-users to validate a force field is to run simulations to reproduce known experimental properties of interest to the user's project.
        However, it follows from the above explanation that doing so will not guarantee all the parameters are sensible.
        The QM calculations detailed in the aforementioned paper and tutorial are often complementary in this respect, in the sense that they can precisely pinpoint parameters in need of optimization, and can directly be used to guide this optimization.
      </p>
    ],
  },
  {
    question: "The output has an incorrect total charge. What should I do?",
    details: [""],
    answer: [
      <p>
        This is a strong indication that the program used incorrect bond orders. If this is the case, the atom types, charges, or parameters are meaningless for your molecule of interest.
      </p>,
      <p>
        Possible remedies include:
        <ul>
          <li>If you prepared your molecule Mol2 by converting from other format, for example OpenBabel may have made a mistake in the conversion to Mol2. Please try to generate and submit a valid Mol2 file.</li>
          <li>If you submitted your molecule in Mol2 format, please check whether the bond orders in the Mol2 file are correct.</li>
          <li>If you thoroughly verified all of the above and the problem persists, you might have hit a rare bug in the atom typing rules. In this case, please contact us.</li>
        </ul>
      </p>

    ],
  },
  {
    question: "What does the “no valid resonance structure found” warning mean?",
    details: [""],
    answer: [
      <p>
        In the vast majority of cases, this means there are errors in the connectivity of the molecule, as discussed more elaborately in <a href="#0">the first FAQ entry</a>.
      </p>
    ],
  },
  {
    question: "How do I get around the “aromatic subgraph too large” warning?",
    details: [""],
    answer: [
      <p>
        If your Mol2 file contains bonds that are marked aromatic, ("ar" in the @&lt;TRIPOS&gt;BOND section), assign (sensible) single and double bonds instead.
        The softwares listed above may assist you in doing so. The CGenFF program internally operates on a single resonance structure; not feeding it aromatic bonds saves it the effort of converting them to single and double bonds.
      </p>
    ],
  },
  {
    question: "Why doesn't my .str file contain all parameters that apply to my molecule?",
    details: [""],
    answer: [
      <p>
        The CHARMM General Force Field (CGenFF) program - to which cgenff.com provides an interface - only outputs new parameters that are required for your molecule, as opposed to parameters that are already present in the main CGenFF parameter file.
        You may choose to turn on the include all parameters option to get all parameters in your .str output.
      </p>
    ],
  },
  {
    question: "Why aren't there improper dihedrals on all sp2 atoms/planar centers in my molecule?",
    details: [""],
    answer: [
      <p>
        In CGenFF, out-of-plane motions are often reproduced correctly by the valence angle and (proper) dihedral terms alone.
        Some moieties (most notably carbonyl groups) do consistently need improper dihedrals, but most centers don't.
      </p>
    ],
  },
  {
    question: `I received this error message "Error generating the parameters in GROMACS format". What does it mean?`,
    details: [""],
    answer: [
      <p>
        This error occurs because there are incorrect atom types and bond types in your input Mol2 file.
        In most cases, the error occurs because of the "ar" bond type not being used correctly.
      </p>,
      <p>
        You can still get charges and parameters for your molecule by running the CGenFF program without the "Add GROMACS Format" option.
      </p>,
    ],
  },
  {
    question: "My molecule has really high penalties. Does this mean I should instead rather use force field X or empirical parameter generation interface Y instead?",
    details: [""],
    answer: [
      <p>
        Class I additive force field parameters, in particular dihedrals and charges, have limited transferability between molecules.
        Short of explicit parameter optimization using QM or experimental target data, parameters for small organic molecules are almost always obtained with analogy- and wildcard-based schemes, which inevitably suffer from this transferability problem.
        For some molecules, close analogies or closely matching wildcard may be found, resulting in an acceptable description on the compound's physical behavior, but for many other molecules, the description may be of limited accuracy regardless of the force field or parameter generation interface.
        The CGenFF program has the advantage that it gives the user an estimate of the extent of analogy of the assigned parameter to that already available in the CGenFF force field (eg. high penalties indicate low analogy with available parameters). Note that in many cases when high penalties are obtained the accuracy of the parameters may be adequate for the majority of applications. In addition, the CGenFF program occasionally rejects a badly-supported functional group as parameters of adequate analogy are not available for the molecule in the force field.
        As mentioned above, the only real solution to the transferability problem is explicit parameter optimization; see <a href="#4">this FAQ entry</a>.
      </p>
    ],
  },
  {
    question: "How can I use CGenFF on a complex containing multiple small organic molecules?",
    details: [""],
    answer: [
      <p>
        There is a one-to-one correspondence between the @&lt;TRIPOS&gt;MOLECULE entry in the input Mol2 file and the RESI entry in the output topology section.
        This implies that a @&lt;TRIPOS&gt;MOLECULE record containing multiple molecules will give rise to a RESI entry containing multiple molecules.
        However, the latter is in violation of CHARMM conventions; a supramolecular RESI may cause problems in simulations.
        Therefore, the recommended course of action is to submit each molecular species in your complex separately to the CGenFF program, then use your MD software of choice to assemble your system.
      </p>,
      <p>
        For exapmle, in CHARMM, the user has the choice between:
        <ul>
          <li>generating all the small molecules as one segment by issuing one READ SEQUence command containing multiple residues, followed by one GENErate command; or</li>
          <li>generating each small molecule as a separate segment with its own READ SEQUence command and GENErate command.</li>
        </ul>
        Note that for PBC calculations, one must be careful to set up imaging correctly depending on the above choice.
      </p>
    ],
  },
  {
    question: "Can I just go to the CGenFF website, submit a number of molecules and get high-quality parameters out?",
    details: [""],
    answer: [
      <p>
        The CGenFF web app only allows for a single molecule at a time to be uploaded, the charges and parameters to be generated, and the output to be downloaded.
        If the user wants to submit multiple molecules simultaneously, they must use a CGenFF program executable on their local computing resources.
        The CGenFF program executable is available to academic laboratories free of charge.  Contact SilcsBio LLC for additional information.
      </p>
    ],
  },

  {
    question: "If I submit a molecule to the CGenFF web interface, how long does it take before I get my parameters?",
    details: [""],
    answer: [
      <p>
        When you submit your molecule to CGenFF web app, the CGenFF program generates parameters and charges by analogy.
        This takes only seconds and does not require user interaction.
      </p>
    ],
  },
  {
    question: "How should I cite CGenFF?",
    details: [""],
    answer: [
      <p>
        Please cite CGenFF using these references.
        Please note that it is important for reproducibility to include the version number of the interface and the force field in your computational details!
      </p>,
      <p>
        1. Vanommeslaeghe, K. Hatcher, E. Acharya, C. Kundu, S. Zhong, S. Shim, J. E. Darian, E. Guvench, O. Lopes, P. Vorobyov, I. and MacKerell, Jr. A.D. "CHARMM General Force Field (CGenFF): A force field for drug-like molecules compatible with the CHARMM all-atom additive biological force fields," Journal of Computational Chemistry 31: 671-90, 2010. DOI: 10.1002/jcc.21367
      </p>,
      <p>
        2. Vanommeslaeghe, K., and MacKerell Jr., A.D., "Automation of the CHARMM General Force Field (CGenFF) I: bond perception and atom typing," Journal of Chemical Informationa and Modeling, 52: 3144-3154, 2012. DOI: 10.1021/ci300363c
      </p>,
      <p>
        3. Vanommeslaeghe, K., Raman, E.P., and MacKerell Jr., A.D., "Automation of the CHARMM General Force Field (CGenFF) II: Assignment of bonded parameters and partial atomic charges, Journal of Chemical Informationa and Modeling, 52: 3155-3168, 2012. DOI: 10.1021/ci3003649
      </p>,
      <p>
        You can also find CGenFF references (item 161, 223, and 224) on Dr. Alex MacKerell's <a href="https://mackerell.umaryland.edu/publications.shtml" target="_blank" rel="noreferrer">website</a>.
      </p>,
    ],
  },
];