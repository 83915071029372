import React, { useEffect, useState } from "react";
import axios from "axios";
import { useNavigate } from 'react-router-dom';
import { DropzoneDialog } from 'mui-file-dropzone';
import { Typography, Dialog, DialogTitle } from "@mui/material";

const UploadFile = ({ userId, userType, jobCount, open, setOpen, saveMol2FileName, saveCenter }) => {
  const navigate = useNavigate();
  const [btnStr, setBtnStr] = useState('Upload MoL2 & Choose Options');
  const [title, setTitle] = useState('');
  const [atLimit, setAtLimit] = useState(false);

  useEffect(() => {
    if (userType === 'Academia' && jobCount >= 150) {
      setTitle(`Looks like you've hit the free job limit! 
        Please wait until Monday 12 AM UTC for the reset.`);
      setAtLimit(true);
    }
  }, []);

  const handleSave = async (files) => {
    for (let file of files) {

      let foundBannedChar = false;
      const banned_list = [" ", "`", "~", "!", "@", "#", "$", "%", "^", "&", "*", "(", ")", "{", "}", "[", "]", "/", "|", "\\", "?", "<", ">", ","];

      for (let bannedChar of banned_list) {
        if (file.name.includes(bannedChar)) {
          const description = bannedChar === " " ? "empty space" : bannedChar;
          setTitle(`Please remove ${description} in the file name`);
          foundBannedChar = true;
          break; 
        }
      }

      if (foundBannedChar) {
        continue; 
      }

      if (file.size < 50) {
        setTitle('The uploaded Mol2 file is invalid (possibly blank). Please double-check the file and reupload');
      } else {
        setBtnStr('Uploading MoL2 File...');

        let jobIDFolder = (new Date().getTime()).toString()

        try {
          const uploadResponse = await axios.post('/jobs/file-management/uploadMol2File', { 'userId': userId, 'jobIDFolder': jobIDFolder, 'mol2File': file }, {
            headers: { "content-type": "multipart/form-data" },
          });

          if (uploadResponse.status === 200) {
            saveMol2FileName(file.name, jobIDFolder);

            const centerResponse = await axios.post('/jobs/file-management/centerMol2', { 'userId': userId, 'jobIDFolder': jobIDFolder, 'mol2File': file.name });

            if (centerResponse.status === 200) {
              saveCenter(centerResponse.data);
              setOpen(false);
              setBtnStr('Upload MoL2 & Choose Options')
              navigate("/options");
            } else if (centerResponse.status === 502) {
              setTitle('Something went wrong. Please try reuploading your Mol2 file');
              setBtnStr('Upload MoL2 & Choose Options')
            }

          } else if (uploadResponse.status === 502) {
            setTitle('Something went wrong. Please try reuploading your Mol2 file');
            setBtnStr('Upload MoL2 & Choose Options')
          }
        } catch (error) {
          if (error.response.status === 700) {
            setTitle('Looks like the uploaded file is not a Mol2 file');
          } else {
            setTitle('Something went wrong. Please try reuploading your Mol2 file');
          }
          setBtnStr('Upload MoL2 & Choose Options')

        }
      }
    }
  };


  return (
    <div>
      {atLimit ?
        <Dialog
          PaperProps={{ sx: { boxShadow: "None" } }}
          open={open}
          onClose={() => setOpen(false)}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">
            <Typography color={'red'} align="center">{title}</Typography>
          </DialogTitle>
        </Dialog>
        :
        <DropzoneDialog
          dialogProps={{
            PaperProps: {
              sx: {
                boxShadow: 'None',
              },
            },
          }}
          dialogTitle={<Typography color={'red'} align="center">{title}</Typography>}
          showAlerts={false}
          useChipsForPreview={true}
          submitButtonText={btnStr}
          previewText={'The Mol2 file that will be uploaded:'}
          open={open}
          onSave={(files) => { setTitle(''); handleSave(files); }}
          acceptedFiles={[".mol2"]}
          showPreviews={true}
          filesLimit={1}
          maxFileSize={1000000} // 1MB
          onDrop={() => setTitle('')}
          onClose={() => { setOpen(false); setTitle(''); }}
        />
      }
    </div>
  );
};

export default UploadFile;
