import React from 'react';
import { connect } from 'react-redux';
import { Outlet, Navigate } from 'react-router-dom';
import { persist } from '../../redux/auth/auth-actions';

const PrivateCGenFFRoute = ({ isAuthenticated, jobId, persist }) => {
  persist()
  return isAuthenticated && jobId ? <Outlet /> : <Navigate to="/input" />;
};


const mapStateToProps = state => {
  return {
    isAuthenticated: state.auth.isAuthenticated,
		jobId: state.job.jobInfo.jobId,
  }
}

const mapDispatchToProps = (dispatch) => {
	return {
		persist: () => dispatch(persist()),
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(PrivateCGenFFRoute);