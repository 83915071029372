import { combineReducers } from 'redux';
import { configureStore } from '@reduxjs/toolkit';
import { persistStore, persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';

import authReducer from '../auth/auth-reducer';
import jobReducer  from '../job/job-reducer';

const configStore = () => {

  const rootPersistConfig = {
    key: 'root',
    storage: storage,
    blacklist: ['auth']
  }

  const persistJobConfig = {
    key: 'job',
    storage: storage,
    // blacklist: ['jobFailed']
  };

  const persistAuthConfig = {
    key: 'auth',
    storage: storage, 
    blacklist: ['authMsg', 'redirect'] 
  };
  
  const store = configureStore({
    reducer: {
      auth : persistReducer(persistAuthConfig, authReducer),
      job  : persistReducer(persistJobConfig, jobReducer),
    },
    middleware: (getDefaultMiddleware) => getDefaultMiddleware({ serializableCheck: false }),
  });

  const persistor = persistStore(store);

  return { store, persistor };
}


export default configStore;