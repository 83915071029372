import {
  SAVE_MOL2FILE_NAME,
  SAVE_CENTER,
  SAVE_JOB_NAME,
  SAVE_JOB_OPTIONS,
  JOB_FAILED,
  RESET_JOB,
} from './job-types'

export const saveMol2FileName = (fileName, jobId) => dispatch => {
  dispatch ({
    type: SAVE_MOL2FILE_NAME,
    payload: {
      jobId    : jobId,
      fileName : fileName,
      stem     : fileName.slice(0, -5)
    }
  })
}

export const saveCenter = (com) => dispatch => {
  dispatch ({
    type: SAVE_CENTER,
    payload: {
      centerx : com[0],
      centery : com[1],
      centerz : com[2]
    }
  })
}

export const saveJobName = (jobName) => dispatch => {
  dispatch ({
    type: SAVE_JOB_NAME,
    payload: {
      jobName : jobName
    }
  })
}

export const jobFailed = (value) => {
  return {
    type: JOB_FAILED,
    payload: {
      jobFailed : value
    }
  }
}

export const saveJobOptions = (e) => {
  return {
    type: SAVE_JOB_OPTIONS,
    payload: {
      name: e.target.name,
      value: e.target.checked,
    }
  }
}

export const resetJob = () => {
  return {
    type: RESET_JOB,
  }
}