import React, { useState } from "react";
import { saveAs } from 'file-saver';
import axios from "axios";

import { makeStyles } from "@mui/styles";
import { Grid, IconButton, Collapse, Typography } from "@mui/material";
import CloudDownloadOutlinedIcon from '@mui/icons-material/CloudDownloadOutlined';
import DeleteForeverOutlinedIcon from '@mui/icons-material/DeleteForeverOutlined';
import FeedOutlinedIcon from '@mui/icons-material/FeedOutlined';
import Tooltip from '@mui/material/Tooltip';

import ConfirmDelete from "./confirm-delete";

const useStyles = makeStyles(() => ({
	icons: {
		display: "flex",
		justifyContent: 'right',
	},
	history: {
		display: 'flex',
		flexDirection: 'row',
		justifyContent: 'left',
		alignItems: 'center'
	}
}));

const JobHistoryFormat = ({
	name,
	downloadLoc,
	jobInfo,
	jobId,
	submitted,
	refresh,
	setRefresh,
}) => {

	const classes = useStyles();
	const [expanded, setExpanded] = useState(false);
	const [open, setOpen] = useState(false);

	const handleExpandClick = () => {
		setExpanded(!expanded);
	};

	const confirm = () => {
		setOpen(true)
	}

	const downloadOrDeleteResults = (action) => {
		axios
			.post('/jobs/file-management/s3ResultsDownloadOrDelete', {
				'Output_S3_Location': downloadLoc,
				'fileName': name,
				'jobId': jobId,
				'action': action
			})
			.then((res) => { if (action==='download') {saveAs(res.data)} })
			.catch((err) => console.log(err))
	}

	return (

		<Grid container className={classes.history}>

			<Grid item xs={8}>
				<Typography style={{ wordWrap: "break-word" }}>
					{name}
				</Typography>
			</Grid>

			<Grid item xs={4} className={classes.icons} >

				<Tooltip title="Show Job Details">
					<IconButton
						size="small" color="primary"
						onClick={() => handleExpandClick()}
					>
						<FeedOutlinedIcon />
					</IconButton>
				</Tooltip>

				{/* download job result from s3 */}
				<Tooltip title="Download Job Result">
					<IconButton
						size="small" color="primary"
						onClick={() => downloadOrDeleteResults('download')}
					>
						<CloudDownloadOutlinedIcon />
					</IconButton>
				</Tooltip>

				{/* delete job result from s3 */}
				<Tooltip title="Delete Job Result">
					<IconButton
						hover
						size="small" color="error"
						onClick={() => confirm()}
					>
						<DeleteForeverOutlinedIcon />
					</IconButton>
				</Tooltip>

			</Grid>

			{/* job details */}
			<Collapse in={expanded} timeout="auto" unmountOnExit>
				<table style={{ fontSize: '12pt', paddingLeft: '20px' }}>
					<tr>
						<td>Job ID</td>
						<td>: {jobId}</td>
					</tr>
					<tr>
						<td>Submitted</td>
						<td>: {submitted}</td>
					</tr>
					<tr>
						<td>Mol2 Output</td>
						<td>: {jobInfo.mol2out ? 'T' : 'F'}</td>
					</tr>
					<tr>
						<td>Include Warnings</td>
						<td>: {jobInfo.warning ? 'T' : 'F'}</td>
					</tr>
					<tr>
						<td>Include Debug </td>
						<td>: {jobInfo.debug ? 'T' : 'F'}</td>
					</tr>
					<tr>
						<td>Copy Params</td>
						<td>: {jobInfo.copyParams ? 'T' : 'F'}</td>
					</tr>
					<tr>
						<td>Gromacs Format</td>
						<td>: {jobInfo.gromacs ? 'T' : 'F'}</td>
					</tr>
				</table>
			</Collapse>

			<ConfirmDelete
				open={open}
				actionName={'delete'}
				action={downloadOrDeleteResults}
				setOpen={setOpen}
				refresh={refresh}
				setRefresh={setRefresh}
			/>

		</Grid>
	);
};

export default (JobHistoryFormat);