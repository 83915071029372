import '@fontsource-variable/montserrat';
import { DrawerImage } from '../media';

const drawerWidth = "270px"
const logoHeight = "140px"
const logoWidth = "212px"
const primaryColor = '#3457a7'
const secondaryColor = '#5ce1e6'
const errorColor = '#d32f32'

const Root = {
  display: 'flex',
  paddingTop: logoHeight,
};

const Main = {
  display: 'flex',
  position: 'relative',
  paddingLeft: drawerWidth,
  flexGrow: 1, 
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
};

const MainWOSideBar = {
  paddingTop: logoHeight,
  display: 'flex',
  position: 'relative',
  flexGrow: 1, 
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
};

const Header = {
  display: 'flex',
  flexDirection: 'row',
  height: logoHeight,
  backgroundImage: `url(${DrawerImage})`,
  backgroundSize: 'cover',
  backgroundPosition: 'center center',
};

const Sidebar = {
  width: drawerWidth,
  backgroundImage: `url(${DrawerImage})`,
  backgroundSize: 'cover',
  backgroundPosition: 'center center',
};

const Title = {
  marginBottom: "20px",
  display: 'flex',
  justifyContent: 'left',
  fontSize: "40px",
  fontWeight: "bold",
  color: primaryColor,
  fontFamily: 'Montserrat Variable',
}

const Logo = {
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  flexDirection: 'column',
  height: logoHeight,
};

const SubTitle = {
  marginBottom: "3px",
  display: 'flex',
  justifyContent: 'left',
  fontSize: "28px",
  fontWeight: "bold",
  fontFamily: 'Montserrat Variable',
}

const SideBarText = {
  justifyContent: 'left',
  fontSize: "18px",
  color: '#FFFFFF',
  fontFamily: 'Montserrat Variable',
}

const Icon = {
  fontSize: "24px",
  color: '#FFFFFF',
}

const SelectedItem = { 
  "&.Mui-selected" : {
    backgroundColor: primaryColor,
    opacity: '0.8'
  },
  "&.Mui-focusVisible": {
    backgroundColor: primaryColor,
    opacity: '0.8'
  },
  ":hover" : {
    backgroundColor: primaryColor,
    opacity: '0.8'
  }
}

export const styles = {
  drawerWidth: drawerWidth,
  logoHeight: logoHeight,
  logoWidth: logoWidth,
  primaryColor: primaryColor,
  secondaryColor: secondaryColor,
  errorColor: errorColor,
  Root: Root,
  Main: Main,
  MainWOSideBar: MainWOSideBar,
  Sidebar: Sidebar, 
  Header: Header,
  Title: Title,
  SubTitle: SubTitle,
  SideBarText: SideBarText,
  Icon: Icon,
  SelectedItem: SelectedItem, 
  Logo: Logo,
}