import React, { useEffect, useState } from "react";
import axios from 'axios';

import {
  PaymentElement,
  useStripe,
  useElements,
  AddressElement,
  LinkAuthenticationElement,
} from "@stripe/react-stripe-js";

import { makeStyles } from '@mui/styles';
import { Button, Typography } from '@mui/material';

const useStyles = makeStyles(() => ({
  root: {
    display: "flex",
    flexDirection: "column",
    gap: "20px",
    padding: '40px 40px 40px 40px',
  },
  form: {
    width: "500px",
    alignSelf: 'center',
    fontFamily: 'Montserrat Variable',
  },
}));

const CheckoutButton = ({
  userId,
  jobId,
  userEmail,
  stripeId,
  setOpen,
  setIsPaid,
  uploadResult,
  updateJobCounts,
  name,
  line1,
  line2,
  city,
  state,
  postal_code,
  country,
  phone,
}) => {
  const stripe = useStripe();
  const elements = useElements();
  const classes = useStyles()

  const [message, setMessage] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [paid, setPaid] = useState(false)
  const [color, setColor] = useState('white')
  const [buttonStr, setButtonStr] = useState('PAY NOW')

  useEffect(() => {
    if (!stripe) {
      return;
    }

    const clientSecret = new URLSearchParams(window.location.search).get(
      "payment_intent_client_secret"
    );

    if (!clientSecret) {
      return;
    }

  }, [stripe]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);

    if (!stripe || !elements) {
      return;
    }

    try {
      const response = await stripe.confirmPayment({
        elements,
        confirmParams: {
          return_url: "",
          receipt_email: userEmail,
        },
        redirect: "if_required"
      });
      if (response.error) {
        setMessage("Please fill in the missing information");
        setColor("red");
      } else {
        setButtonStr('PROCESSING PAYMENT...');

        switch (response.paymentIntent.status) {
          case "succeeded":
            handlePaymentSuccess(response.paymentIntent.id);
            break;
          case "processing":
            handlePaymentProcessing();
            break;
          case "requires_payment_method":
            handlePaymentMethodRequired(response.paymentIntent.id);
            break;
          default:
            handlePaymentError(response.paymentIntent.id);
            break;
        }
      }
    } catch (error) {
      console.error('Error during payment confirmation:', error);
      setMessage("Something went wrong, please try again later.");
      setColor("red");
    } finally {
      setIsLoading(false);
    }
  };

  const savePaymentInfo = async (userId, jobId, paymentId, paymentStatus, paymentAmount, runStatus) => {
    try {
      await axios.post('/jobs/savePaymentInfo2db', {
        'userId': userId,
        'jobId': jobId,
        'paymentId': paymentId,
        'paymentStatus': paymentStatus,
        'paymentAmount': paymentAmount,
        'runStatus': runStatus
      }).catch((err) => console.error(err));
    } catch (error) {
      console.error('Error uploading results:', error);
    }
  };

  const handlePaymentSuccess = (paymentId) => {
    setMessage("Payment successful! Generating parameters...");
    setPaid(true);
    savePaymentInfo(userId, jobId, paymentId, 'Paid', '350', 'completed');
    uploadResult();
    updateJobCounts();
    setColor("green");
    setTimeout(() => { setOpen(false); setIsPaid(true) }, 3000);
  };

  const handlePaymentProcessing = () => {
    setMessage("Your payment is being processed...");
    setColor("green");
  };

  const handlePaymentMethodRequired = (paymentId) => {
    setMessage("Your payment was not successful, please try again.");
    savePaymentInfo(userId, jobId, paymentId, 'Failed', '0','failed');
    setColor("red");
  };

  const handlePaymentError = (paymentId) => {
    setMessage("Something went wrong, please try again later.");
    savePaymentInfo(userId, jobId, paymentId, 'Failed', '0','failed');
    setColor("red");
  };

  const paymentElementOptions = {
    layout: "tabs",
    defaultValues: {
      billingDetails: {
        email: userEmail,
      }
    }
  }

  const addressElementOptions = {
    mode: 'billing',
    fields: {
      phone: 'always',
    },
    validation: {
      phone: {
        required: 'always',
      },
    },
    defaultValues: {
      name,
      address: {
        line1: line1 || '',
        line2: line2 || '',
        city: city || '',
        state: state || '',
        postal_code: postal_code || '',
        country: country || '',
      },
      phone: phone || '',
    },
  };

  const saveAddress = async (event) => {
    if (line1 === null && event.complete) {
      const { address, phone } = event.value;

      const newAddress = { ...address, stripeId, phone };

      try {
        const response = await axios.post("/payment/update", {
          newAddress,
        });

        // Handle the response if needed
        if (response.status === 200) {
          // Do something on success
        } else {
          // Handle error
          console.error("Failed to update address");
        }
      } catch (error) {
        console.error("Error occurred:", error);
      }
    }
  };

  return (
    <div className={classes.root}>
      <LinkAuthenticationElement />

      <AddressElement
        id="address-element"
        onChange={(e) => saveAddress(e)}
        options={addressElementOptions}
        className={classes.form}
      />

      <PaymentElement
        id="payment-element"
        options={paymentElementOptions}
        className={classes.form}
      />

      <Typography align='center' sx ={{fontWeight: 'bold'}}>Total Amount: $350</Typography>

      <Button
        disabled={isLoading || !stripe || !elements || paid}
        id="submit"
        size="medium"
        variant="contained"
        color="primary"
        fullWidth
        onClick={handleSubmit}
      >
        {buttonStr}
      </Button>
      {/* Show any error or success messages */}
      {message && <Typography id="payment-message" style={{ color, fontSize: "15pt", alignSelf: "center" }}> {message} </Typography>}
    </div>
  )
}

export default CheckoutButton;
