import React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";

const ConfirmAction = ({ open, setOpen, actionName, refresh, setRefresh, setConfirmDelete }) => {

  let str, confirm;
  if (actionName === 'cancelActiveJob') {
    str = 'CANCEL the current job? No refund will be issued. '
    confirm = 'Job Cancellation'
  } else if (actionName === 'dequeued') {
    str = 'REMOVE job from the queue? '
    confirm = 'Queued Job Remove'
  } else if (actionName === 'delete') {
    str = 'DELETE this job result? '
    confirm = 'Job Result Deletion'
  }

  return (
    <div>
      <Dialog
        PaperProps={{sx: {boxShadow: "None"}}}
        open={open}
        onClose={() => setOpen(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          Confirm {confirm}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {str}
            This action is irreversible.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button variant="outlined" onClick={() => {setOpen(false); setConfirmDelete(false)} } color="primary">
            No
          </Button>
          <Button variant="outlined" onClick={() => { setOpen(false); setRefresh(!refresh); setConfirmDelete(true) } } color="primary" >
            Yes
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default ConfirmAction;