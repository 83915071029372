import { connect } from 'react-redux';
import {
  MaterialReactTable,
  useMaterialReactTable,
} from 'material-react-table';
import { format } from "date-fns";

import SvgIcon from '@mui/material/SvgIcon';

import { Dialog } from '@mui/material';

import React, { useEffect, useState } from "react";
import axios from 'axios';

import { Grid, Paper, IconButton, Box, Tooltip, Typography } from "@mui/material";

import { LoadingDna } from "../../components";

import { styles } from "../../app";
import DeleteIcon from '@mui/icons-material/DeleteForeverOutlined';
import FeedOutlinedIcon from '@mui/icons-material/FeedOutlined';
import SwapHorizontalCircleOutlinedIcon from '@mui/icons-material/SwapHorizontalCircleOutlined';
import { saveAs } from 'file-saver';

import ConfirmDelete from "./utils/confirm-delete";
import { BetaBanner } from '../../components'

const fetchJobInfo = async (userId, setData, setIsLoading, setMessage) => {
  await axios.post('/jobs/retrival/fetchJobsByUserId', { 'userId': userId, 'displayResultsInConsole': false })
    .then((res) => {
      setData(res.data)
      setIsLoading(false)
    }).catch(() => {
      setData([])
      setMessage("There was a problem loading the job history! Please try logging out and in again.")
      setIsLoading(false)
    })
}

const downloadOrDeleteResults = (action, downloadLoc, jobId, userId) => {
  axios
    .post('/jobs/file-management/s3ResultsDownloadOrDelete', {
      'Output_S3_Location': downloadLoc,
      'userId': userId,
      'jobId': jobId,
      'action': action
    })
    .then((res) => { if (action === 'download') {saveAs(res.data)} })
    .catch((err) => console.log(err))
}

const convertToGromacs = (userId, jobId, stem, fileName, setRefresh, setMessage, setIsLoading, setTextColor) => {
  setIsLoading(true)
  axios.post('/job-managers/submitGromacs', {
    'userId': userId,
    'jobId': jobId,
    'fileName': fileName,
    'stem': stem
  })
  .then((res) => {
    if (res.status === 200) {
      setMessage('Successfully converted to Gromacs format. Click anywhere to continue')
      setIsLoading(false)
      setRefresh(true)
      setTextColor('green')
    }
  })
  .catch((err) => {
    if (err.response.data === 'GRMX') {
      setMessage('The mol2 file that was used cannot be converted to Gromacs format. Click anywhere to continue')
      setTextColor('red')
    }
  })
}

const columns = [
  {
    accessorKey: 'File_Name',
    header: 'Mol2 File Name',
    size: 350,
  },
  {
    accessorKey: 'Job_Name',
    header: 'Job Name',
    size: 200,
  },
  {
    accessorFn: (row) => format(new Date(`${row.Submitted_Date_Time}` + 'Z'), "yyyy-MMM-dd HH:mm"),
    header: 'Date Run',
    size: 200,
  }
];

const JobHistoryTable = ({ userId, stem }) => {

  const [refresh, setRefresh] = useState(false)
  const [isLoading, setIsLoading] = useState(true)
  const [message, setMessage] = useState("");
  const [data, setData] = useState([])
  const [open, setOpen] = useState(false);
  const [confirmDelete, setConfirmDelete] = useState(false)
  const [tempJobId, setTempJobId] = useState("");
  const [tempJobLoc, setTempJobLoc] = useState("")
  const [textColor, setTextColor] = useState('')

  const handleClose = () => {setMessage(""); setRefresh(true)}


  useEffect(() => {
    const intervalId = setInterval(() => {
      fetchJobInfo(userId, setData, setIsLoading, setMessage);
    }, 60000);
    return () => clearInterval(intervalId);
  }, []);

  useEffect(() => {
    fetchJobInfo(userId, setData, setIsLoading, setMessage);
    setRefresh(false)
  }, [userId, refresh]);

  const deleteAction = (downloadLoc, jobId) => {
    setOpen(true)
    setTempJobId(jobId)
    setTempJobLoc(downloadLoc)
  }

  useEffect(() => {
    if (confirmDelete) {
      downloadOrDeleteResults('delete', tempJobLoc, tempJobId, userId)
      setConfirmDelete(false)
    }
  }, [confirmDelete]);

  const table = useMaterialReactTable({
    columns,
    data,
    setRefresh,
    setMessage,
    setIsLoading,
    setTextColor,

    muiTablePaperProps: () => ({ sx: { boxShadow: "None" }, }),
    enableFullScreenToggle: false,
    enableDensityToggle: false,

    // hide expand column
    initialState: {
      columnVisibility: { 'mrt-row-expand': false }
    },

    displayColumnDefOptions: {
      'mrt-row-actions': {
        size: 200,
      },
      // hide expand column
      'mrt-row-expand': {
        enableHiding: true,
        visibleInShowHideMenu: false
      }
    },

    // job details
    renderDetailPanel: ({ row }) => (
      <Box
        sx={{
          display: 'grid',
          margin: 'auto',
          gridTemplateColumns: '1fr 1fr',
          width: '70%',
        }}
      >
        <Typography><b>Job ID</b>: {row.original.Job_ID}</Typography>
        {/* <Typography><b>Gromacs Format</b>: {JSON.parse(row.original.Job_Options_Json).gromacs.toString()}</Typography> */}
        <Typography><b>Mol2 Output</b>: {JSON.parse(row.original.Job_Options_Json).mol2out.toString()}</Typography>
        <Typography><b>Copy Params</b>: {JSON.parse(row.original.Job_Options_Json).copyParams.toString()}</Typography>
        <Typography><b>Include Warnings</b>: {JSON.parse(row.original.Job_Options_Json).warning.toString()}</Typography>
        <Typography><b>Include Debugs</b>: {JSON.parse(row.original.Job_Options_Json).debug.toString()}</Typography>
      </Box>
    ),
    enableRowActions: true,
    positionActionsColumn: 'last',
    renderRowActions: ({ row }) => (
      <Box sx={{ display: 'flex', flexWrap: 'nowrap', gap: '8px' }}>
        <Tooltip title="Show Job Details">
          <IconButton color="primary"
            onClick={() => row.toggleExpanded()}
          >
            <FeedOutlinedIcon />
          </IconButton>
        </Tooltip>
        <Tooltip title={row.original.Gromacs === 0 ? "Gromacs Conversion Not Available" : row.original.Output_Gromacs_Location === null ? "Convert to Gromacs Format" : "Already Converted to Gromacs Format"}>
          <div>
            <IconButton 
              disabled={JSON.parse(row.original.Job_Options_Json).gromacs || row.original.Output_Gromacs_Location !== null || new Date(row.original.Submitted_Date_Time.split(' ')[0]) < new Date('2024-06-20') || row.original.Gromacs === 0} 
              color="primary"
              onClick={() => convertToGromacs(userId, row.original.Job_ID, stem, row.original.File_Name, setRefresh, setMessage, setIsLoading, setTextColor)}
            >
              <SwapHorizontalCircleOutlinedIcon />
            </IconButton>
          </div>
        </Tooltip>
        <Tooltip title={row.original.Output_Gromacs_Location === null ? "Gromacs Format Result Not Available" : "Download Gromacs Format Result"}>
          <div>
            <IconButton 
              color="primary" 
              disabled={row.original.Output_Gromacs_Location === null}
              onClick={() => downloadOrDeleteResults('download', row.original.Output_Gromacs_Location, row.original.Job_ID, userId)}>
              <SvgIcon>
                <svg 
                  xmlns="http://www.w3.org/2000/svg" 
                  viewBox="0 0 23.5 23.5"
                  fill="none"
                  strokeWidth={1.5}
                  stroke="currentColor"
                >
                  <path 
                    class="st0" 
                    d="M18.01,8.04c-0.64,0-1.24,0.14-1.79,0.38c-1.09-1.74-3.02-2.9-5.23-2.9c-2.73,0-5.05,1.78-5.86,4.24
                    c0,0,0,0,0,0c-2,0-3.62,1.62-3.62,3.62s1.62,3.62,3.62,3.62c0.7,0,1.35-0.21,1.91-0.55c1.07,0.9,2.45,1.44,3.96,1.44
                    c1.69,0,3.23-0.68,4.34-1.79c0.75,0.56,1.67,0.91,2.67,0.91c2.47,0,4.48-2,4.48-4.48C22.49,10.04,20.49,8.04,18.01,8.04z"/>
                  <g>
                    <path 
                      strokeWidth={1}
                      fill="currentColor"
                      d="M12.9,14.6c-0.3,0.1-1,0.3-1.8,0.3c-0.9,0-1.6-0.2-2.2-0.8c-0.5-0.5-0.8-1.2-0.8-2.1c0-1.7,1.2-3,3.1-3
                      c0.7,0,1.2,0.1,1.4,0.3l-0.2,0.6c-0.3-0.1-0.7-0.2-1.3-0.2C9.9,9.7,9,10.6,9,12c0,1.5,0.9,2.3,2.2,2.3c0.5,0,0.8-0.1,1-0.2v-1.7H11
                      v-0.6h1.9V14.6z"
                    />
                  </g>
                </svg>
              </SvgIcon>
              {/* <DownloadIcon /> */}
            </IconButton>
          </div>
        </Tooltip>
        <Tooltip title="Download Result">
          <IconButton color="primary" onClick={() => downloadOrDeleteResults('download', row.original.Output_S3_Location, row.original.Job_ID, userId)}>
            <SvgIcon>
              <svg 
                xmlns="http://www.w3.org/2000/svg" 
                viewBox="0 0 23.5 23.5"
                fill="none"
                strokeWidth={1.5}
                stroke="currentColor"
              >
                <path 
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  class="st0" 
                  d="M18.01,8.04c-0.64,0-1.24,0.14-1.79,0.38c-1.09-1.74-3.02-2.9-5.23-2.9c-2.73,0-5.05,1.78-5.86,4.24
                  c0,0,0,0,0,0c-2,0-3.62,1.62-3.62,3.62s1.62,3.62,3.62,3.62c0.7,0,1.35-0.21,1.91-0.55c1.07,0.9,2.45,1.44,3.96,1.44
                  c1.69,0,3.23-0.68,4.34-1.79c0.75,0.56,1.67,0.91,2.67,0.91c2.47,0,4.48-2,4.48-4.48C22.49,10.04,20.49,8.04,18.01,8.04z"/>
                <g>
                  <path 
                    strokeWidth={1}
                    fill="currentColor"
                    d="M12.6,14.8c-0.3,0.1-0.8,0.3-1.6,0.3c-1.7,0-2.9-1.1-2.9-3c0-1.9,1.3-3.1,3.1-3.1c0.7,0,1.2,0.2,1.4,0.3l-0.2,0.6
		                c-0.3-0.1-0.7-0.2-1.2-0.2c-1.4,0-2.3,0.9-2.3,2.5c0,1.5,0.8,2.4,2.3,2.4c0.5,0,0.9-0.1,1.3-0.2L12.6,14.8z"
                  />
                </g>
              </svg>
            </SvgIcon>
          </IconButton>
        </Tooltip>
        <Tooltip title="Delete Job Result">
          <IconButton color="error" onClick={() => deleteAction(row.original.Output_S3_Location, row.original.Job_ID)}>
            <DeleteIcon />
          </IconButton>
        </Tooltip>
      </Box>
    ),
  });

  return (
    <div>
      <Grid item xs={12} paddingBottom={5} style={{ display: 'flex', justifyContent: 'center' }}>
        <BetaBanner />
      </Grid>
    <Paper style={{
      padding: '40px', minWidth: "900", maxWidth: "1100px",
      display: 'flex',
      flexDirection: 'column',
      alignItems: "center",
    }}>
      <Typography padding={4}>
        <Grid container style={styles.Title}>
          JOB HISTORY
        </Grid>
        {isLoading ?
          <LoadingDna size='60' />
          :
          <MaterialReactTable table={table} />
        }

        <ConfirmDelete
          open={open}
          setOpen={setOpen}
          actionName={'delete'}
          refresh={refresh}
          setRefresh={setRefresh}
          setConfirmDelete={setConfirmDelete}
        />
        <Dialog
          open={message}
          onClose={handleClose}
          PaperProps={{ sx: { boxShadow: "None" } }}
        >
          <Typography align="center" variant="body1" sx={{ color: textColor }} padding={2}>
            {message}
          </Typography>  
        </Dialog>
      </Typography>
    </Paper>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    userId: state.auth.user.userId,
    stem: state.job.jobInfo.stem,
  };
};

export default connect(mapStateToProps)(JobHistoryTable);
