import Step1 from './tutorial-png-academia/step1.png'
import Step2 from './tutorial-png-academia/step2.png'
import Step3 from './tutorial-png-academia/step3.png'
import Step4 from './tutorial-png-academia/step4.png'
import Step5 from './tutorial-png-academia/step5.png'
import Step6 from './tutorial-png-academia/step6.png'
import Step7 from './tutorial-png-academia/step7.png'
import Step8 from './tutorial-png-academia/step8.png'
import Step9 from './tutorial-png-academia/step9.png'
import Step10 from './tutorial-png-academia/step10.png'
import Step11 from './tutorial-png-academia/step11.png'
import Step12 from './tutorial-png-academia/step12.png'
import Step13 from './tutorial-png-academia/step13.png'

import { Typography } from '@mui/material'

const tutorialStepsAcademia = [
	{
		label: "Start CGenFF Job",
		/* Needed for  the first step, as the React Swipeable View will default to a height of 0px for the first image
			 if "animateHeight" is set to true and no image height is set. */
		height: '469.5dp',
		width: '960dp',
		imgPath: Step1,
		description: "Click “Start CGenFF Job” on the left sidebar menu to start a new CGenFF job with your molecule."
	},

	{
		label: "Step 1. Upload a molecule file in Mol2 format",
		height: '469.5dp',
		width: '960dp',
		imgPath: Step2,
		description: "Click the “RUN CGenFF” button."
	},

	{
		label: "",
		height: '469.5dp',
		width: '960dp',
		imgPath: Step3,
		description:
			"Drag and drop the Mol2 file or click the upload icon to select your file."
	},

	{
		label: "",
		height: '469.5dp',
		width: '960dp',
		imgPath: Step4,
		description: "Click the “Upload Mol2 & Choose Options” button."
	},

	{
		label: "Step 2. Choose CGenFF options for your molecule",
		height: '469.5dp',
		width: '960dp',
		imgPath: Step5,
		description:
			<Typography>
				-	Review your molecule in the blue preview screen. You can manipulate the view by clicking, dragging, and scrolling with your mouse to rotate and zoom in or out.<br />
				-	Choose a job name. This job and its related files can be retrieved later in your job history.<br />
				-	Select CGenFF options. You may choose to output additional information for your CGenFF job:<br />
				<Typography marginLeft={5}>
				•	“Output Mol2” will generate a processed Mol2 file of the molecule after assigning your parameters. Atom names will be modified in accordance with CHARMM formatting.<br />
				•	“Include Warnings” will display any warning information resulting from the CGenFF program execution.<br />
				•	“Include Debug Info” will display debugging information.<br />
				•	“Copy Existing Params” will copy existing CHARMM General Force Field parameters to the output.<br />
				Note: The option to convert to GROMACS format is now in a later step.<br />
				</Typography>
				-	After you have reviewed your molecule and selected your desired options, click the "Process Input" button.<br />
			</Typography>
	},

	{
		label: "Step 3. Run CGenFF Engine",
		height: '469.5dp',
		width: '960dp',
		imgPath: Step6,
		description:
			"Review your file and click “Run CGenFF Engine”"
	},

	{
		label: "Step 3. Review and download the results",
		height: '469.5dp',
		width: '960dp',
		imgPath: Step7,
		description:
			"After the job is completed, you will be able to view the results on the right side of the page. Viewable results include the stream file, log file, current job options, and output Mol2 file."
	},

	{
		label: "",
		height: '469.5dp',
		width: '960dp',
		imgPath: Step8,
		description:
			"You can view the CGenFF job log file by clicking “Log File”."
	},

	{
		label: "",
		height: '469.5dp',
		width: '960dp',
		imgPath: Step9,
		description:
			"You can view a summary of the CGenFF job information by clicking “CGenFF Job Info”."
	},

	{
		label: "",
		height: '469.5dp',
		width: '960dp',
		imgPath: Step10,
		description:
			"You can view your input Mol2 file by clicking “Mol2 File”."
	},

	{
		label: "",
		height: '469.5dp',
		width: '960dp',
		imgPath: Step11,
		description:
			"You may now convert the file to GROMACS format by clicking “Convert to GROMACS”."
	},

	{
		label: "",
		height: '469.5dp',
		width: '960dp',
		imgPath: Step12,
		description:
			"You may now download the file in GROMACS format by clicking “Download GROMACS format”."
	},

	{
		label: "",
		height: '469.5dp',
		width: '960dp',
		imgPath: Step13,
		description:
			"You may also convert and download the file in GROMACS format from the “Job History” page."
	},
];

export default tutorialStepsAcademia;