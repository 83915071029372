import React, { useState } from "react";
import { connect } from "react-redux";

import axios from "axios";

import { makeStyles } from "@mui/styles";
import {
	Alert,
	Box,
	List,
	ListItem,
	ListItemText,
	Divider,
	Snackbar,
	Typography,
	IconButton,
	Switch,
	Paper,
	Grid
} from "@mui/material";
import EditIcon from '@mui/icons-material/EditOutlined';

import EditInfo from './utils/edit-info'
import { styles } from "../../app";
import { saveReceiveUpdate } from "../../redux/auth/auth-actions";
import { BetaBanner } from "../../components";

const useStyles = makeStyles(() => ({
	root: {
		width: '800px',
		display: 'flex',
		flexDirection: 'column',
	},
}));

const Settings = ({
	userId,
	userEmail,
	jobCount,
	firstName,
	lastName,
	company,
	userType,
	authMsg,
	receiveUpdates,
	saveReceiveUpdate
}) => {

	const classes = useStyles();
	const [show, setShow] = useState(false);
	const [open, setOpen] = useState(false);
	const [actionName, setActionName] = useState('');
	const [checked, setChecked] = useState(receiveUpdates);

	const handleChange = (e) => {
		e.preventDefault()
		setChecked(e.target.checked)
		saveReceiveUpdate(e.target.checked)
		axios.post('/user-managers/updateReceiveEmail', { 'userId': userId, 'receiveUpdates': e.target.checked })
		setShow(true)
	}

	const handleClose = () => {
		setShow(false);
	};

	const confirm = () => {
		setOpen(true);
	}


	return (
		<div>
			<Grid item xs={12} paddingBottom={5} style={{ display: 'flex', justifyContent: 'center' }}>
        <BetaBanner />
      </Grid>
		<Paper style={{
			padding: '20px', minWidth: "900px", maxWidth: "1100px", display: 'flex',
			flexDirection: 'column',
			alignItems: "center",
		}}>

			<Typography padding={4}>

				<Box className={classes.root}>

					<Box style={styles.Title}>
						Account Settings
					</Box>

					<Divider color='black' />

					<div style={{ paddingTop: '30px', marginLeft: '60px', marginRight: '60px' }}>
						<List
							subheader={
								<Typography style={styles.SubTitle}>
									Account Information
									<IconButton onClick={() => {
										confirm();
										setActionName("updatePassword");
									}}>
										<EditIcon style={{ color: '#3457a7', fontSize: "24pt" }} />
									</IconButton>
								</Typography>
							}
						>
							<div style={{ marginLeft: '30px', marginRight: '30px' }}>
								<ListItem>
									<ListItemText
										primary="Email"
										secondary={userEmail}
									/>
								</ListItem>
								<ListItem>
									<ListItemText
										primary="Password"
										secondary="***********"
									/>
								</ListItem>

								{userType === 'Academia' ?
									<ListItem>
										<ListItemText
											primary="Number of Jobs Ran This Week (Reset every Monday at 12AM UTC)"
											secondary={jobCount}
										/>
									</ListItem> : null
								}
							</div>
						</List>
					</div>

					<Divider color='black' />

					<div style={{ paddingTop: '30px', marginLeft: '60px', marginRight: '60px' }}>
						<List
							subheader={
								<Typography style={styles.SubTitle}>
									Personal Information
									<IconButton onClick={() => {
										confirm();
										setActionName("updatePersonalInfo");
									}}>
										<EditIcon style={{ color: '#3457a7', fontSize: "24pt" }} />
									</IconButton>
								</Typography>
							}
						>
							<div style={{ marginLeft: '30px', marginRight: '30px' }}>

								<ListItem>
									<ListItemText
										primary="Name"
										secondary={firstName + ' ' + lastName}
									/>
								</ListItem>
								<ListItem>
									<ListItemText
										primary={userType === 'Academia' ? "Institution" : "Company"}
										secondary={company}
									/>
								</ListItem>
								<ListItem>
									<ListItemText
										primary="Account Type"
										secondary={userType}
									/>
								</ListItem>
							</div>
						</List>
					</div>

					<Divider color='black' />

					<div style={{ paddingTop: '30px', marginLeft: '60px', marginRight: '60px' }}>
						<List
							subheader={
								<Typography style={styles.SubTitle}>
									Permissions
								</Typography>
							}
						>
							<div style={{ marginLeft: '30px', marginRight: '30px' }}>

								<ListItem>
									<ListItemText primary="Receive news and product updates via email" />
									<Switch
										edge="end"
										checked={checked}
										onChange={handleChange}
									/>
								</ListItem>
							</div>

						</List>
					</div>

					<Snackbar
						autoHideDuration={2000}
						anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
						open={show}
						onClose={handleClose}
					>
						<Alert onClose={handleClose} severity="success">
							Settings succesfully saved!
						</Alert>
					</Snackbar>

				</Box>

				<EditInfo
					open={open}
					actionName={actionName}
					setOpen={setOpen}
					setShow={setShow}
					firstName={firstName}
					lastName={lastName}
					company={company}
					userId={userId}
					userEmail={userEmail}
					authMsg={authMsg}
				/>

			</Typography>
		</Paper>
		</div>
	);
};



const mapStateToProps = (state) => {
	return {
		authMsg: state.auth.user.authMsg,
		userId: state.auth.user.userId,
		userEmail: state.auth.user.userEmail,
		firstName: state.auth.user.firstName,
		lastName: state.auth.user.lastName,
		company: state.auth.user.company,
		userType: state.auth.user.userType,
		receiveUpdates: state.auth.user.receiveUpdates,
		jobCount: state.auth.user.jobCount
	};
};

const mapDispatchToProps = (dispatch) => {
	return {
		saveReceiveUpdate: (checked) => dispatch(saveReceiveUpdate(checked))
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(Settings);