import React from "react";
import { Typography, Box, Grid, Paper, Button, Link } from "@mui/material";
import { styles } from "../../app";
import { BetaBanner } from "../../components";
const PricingInfo = () => {

  return (
    <div>
      <Grid item xs={12} paddingBottom={5} style={{ display: 'flex', justifyContent: 'center' }}>
        <BetaBanner />
      </Grid>
    <Paper style={{
      padding: '20px', minWidth: "900px", maxWidth: "1100px", display: 'flex',
      flexDirection: 'column',
      alignItems: "center",
    }}>
      <Typography padding={4}>

        <Box style={styles.Title}>
          PRICING GUIDE
        </Box>

        <Grid container alignItems="center" justifyContent="space-between">
          <Grid item xs={12} style={styles.SubTitle}>
            CGenFF WEB APP
          </Grid>
          <Grid item xs={12}>
            {/* <p style={{ marginLeft: '60px', marginRight: '60px' }}>
              Academic User: Free 150 molecules/week
            </p> */}
            <p style={{ marginLeft: '60px', marginRight: '60px' }}>
              Flat rate of $350/molecule
            </p>
          </Grid>

          <Grid item xs={12} style={styles.SubTitle}>
            PAYMENT POLICY
          </Grid>
          <Grid item xs={12}>
            <p style={{ marginLeft: '60px', marginRight: '60px' }}>
              The price for each molecule is shown under "CGenFF Job Info" when a molecule has
              been uploaded prior to purchase. This is a one-time, non-refundable cost to run your
              molecule through the CGenFF engine. Credit card payment must be made through
              our system prior to starting a job. We are not able to offer refunds on molecules once
              they have been processed.
            </p>
          </Grid>
          <Grid item xs={12} style={styles.SubTitle}>
            LOOKING FOR A HIGHER VOLUME?
          </Grid>
          
          <Grid item xs={12}>
            <p style={{ marginLeft: '60px', marginRight: '60px' }}>
              CGenFF software is also available as a yearly subscription to individuals and
              companies that need a larger processing volume. To learn more about a CGenFF
              yearly subscription, follow the link below to contact our sales team.
            </p>
          </Grid>

          <Grid item xs={12} style={styles.SubTitle}>
            LOOKING TO FURTHER OPTIMIZE YOUR PARAMETERS?
          </Grid>
          <Grid item xs={12}>
            <p style={{ marginLeft: '60px', marginRight: '60px' }}>
              SilcsBio can provide additional tools and/or services if a user is interested in
              further optimization of parameters for molecules with high penalty scores. 
              To learn more, follow the link below to contact our sales team.
            </p>
          </Grid>
        </Grid>

        <Grid paddingTop={2} item xs={12} style={{display: 'flex', justifyContent: 'center'}}>
        <Link href="https://cgenff.com/contact" target="_blank">
          <Button
            variant="contained"
            color="primary"
          >
            CONTACT SALES
          </Button>
          </Link>
        </Grid>

      </Typography>
    </Paper>
    </div>


  );
}

export default PricingInfo