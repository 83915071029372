import React, { useState } from 'react';
import { connect } from "react-redux";

import { Grid } from '@mui/material'
import { makeStyles } from '@mui/styles';

import { Quickstart, NewsAndUpdates, JobHistory } from './utils';
import { resetJob, saveMol2FileName, saveCenter } from "../../redux/job/job-actions";
import { BetaBanner } from '../../components';


const useStyles = makeStyles(() => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    gap: '25px',
  },
}));

const Dashboard = ({ userId, userType, jobCount, jobName, fileName, resetJob, saveMol2FileName, saveCenter }) => {

  const classes = useStyles();
  const [open, setOpen] = useState(false)

  return (

    <div className={classes.root}>

      <Grid container style={{ width: "1200px"}} >

        <Grid item xs={12} paddingBottom={5} style={{ display: 'flex', justifyContent: 'center' }}>
          <BetaBanner />
        </Grid>

        <Grid container item xs={7.5} style={{ justifyContent: 'center', minHeight: "700px" }}>
          
          <Grid item xs={11.5}>
            <Quickstart
              userId={userId}
              userType={userType}
              jobCount={jobCount}
              resetJob={resetJob}
              saveMol2FileName={saveMol2FileName}
              open={open}
              setOpen={setOpen}
              saveCenter={saveCenter}
            />
          </Grid>

          <Grid item xs={11.5} style={{ paddingTop: "25px" }} >
            <NewsAndUpdates userType={userType}/>
          </Grid>

        </Grid>

        <Grid container item xs={4.5} style={{ justifyContent: 'center' }} rowSpacing={2}>
          <Grid item xs={11.5}>
            <JobHistory userId={userId} jobName={jobName} fileName={fileName} />
          </Grid>
        </Grid>

      </Grid>

    </div>
  )
};

const mapStateToProps = (state) => {
  return {
    userId: state.auth.user.userId,
    userType: state.auth.user.userType,
    jobCount: state.auth.user.jobCount,
    jobName: state.job.jobInfo.jobName,
    fileName: state.job.jobInfo.fileName,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    saveMol2FileName: (fileName, jobId) => dispatch(saveMol2FileName(fileName, jobId)),
    resetJob: () => dispatch(resetJob()),
    saveCenter: (com) => dispatch(saveCenter(com)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Dashboard);
