import React from 'react';
import { connect } from 'react-redux';
import { Outlet, Navigate } from 'react-router-dom';
import { persist } from '../../redux/auth/auth-actions';

const PrivateAcademiaRoute = ({ isAuthenticated, userType, persist }) => {
  persist()
  return isAuthenticated && userType !== 'Academia' ? <Outlet /> : <Navigate to="/homepage" />;
};


const mapStateToProps = state => {
  return {
    isAuthenticated: state.auth.isAuthenticated,
		userType: state.auth.user.userType,
  }
}

const mapDispatchToProps = (dispatch) => {
	return {
		persist: () => dispatch(persist()),
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(PrivateAcademiaRoute);