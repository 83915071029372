import { useEffect, useCallback } from 'react';
import { connect } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { logoutUser } from '../../redux/auth/auth-actions';
import { resetJob } from '../../redux/job/job-actions';

import axios from 'axios';

const Logout = ({ userId, logoutUser, resetJob }) => {
  const navigate = useNavigate();

  const handleLogout = useCallback(async () => {
    try {
      await axios.post('/jobs/file-management/deleteFolder', { userId });
      await logoutUser();
      resetJob();
      navigate('/login');
    } catch (error) {
      console.error('Error during logout:', error);
    }
  }, [userId, logoutUser, resetJob]);

  useEffect(() => {
    handleLogout();
  }, [handleLogout]);

  return null;
};

const mapStateToProps = (state) => ({
  userId: state.auth.user.userId,
});

const mapDispatchToProps = {
  logoutUser,
  resetJob,
};

export default connect(mapStateToProps, mapDispatchToProps)(Logout);
