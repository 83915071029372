import React from "react";
import { Dialog, DialogContent, DialogTitle, IconButton } from "@mui/material"
import CloseIcon from '@mui/icons-material/Close';
import Carousel from "./carousel";


const TutorialCarousel = ({ open, setOpen, tutorialSteps }) => {

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <Dialog onClose={handleClose} open={open} maxWidth='lg'
      PaperProps={{
        sx: {
          boxShadow: 'None'
        }
      }}>
      <DialogTitle sx={{ display: 'flex', alignItems: 'center' }}>
        <IconButton sx={{ ml: 'auto' }} onClick={handleClose}>
            <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent style={{ padding: "20px" }}  >
        <Carousel tutorialSteps={tutorialSteps} />
      </DialogContent>
    </Dialog>
  );
}

export default TutorialCarousel
