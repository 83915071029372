import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';

import { makeStyles } from "@mui/styles"
import {
  Drawer,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Grid,
  Typography,
} from '@mui/material';
import WorkIcon from '@mui/icons-material/WorkOutlineOutlined';
import SettingsIcon from '@mui/icons-material/SettingsOutlined';
import ExitToAppIcon from '@mui/icons-material/ExitToApp';
import ListItemButton from '@mui/material/ListItemButton';
import DescriptionIcon from '@mui/icons-material/DescriptionOutlined';
import MonetizationOnIcon from '@mui/icons-material/MonetizationOnOutlined';
import QuestionAnswerIcon from '@mui/icons-material/QuestionAnswerOutlined';
import HomeIcon from '@mui/icons-material/HomeOutlined';
import EmailIcon from '@mui/icons-material/EmailOutlined';
import ListIcon from '@mui/icons-material/ListAltOutlined';
import ThumbsUpDownIcon from '@mui/icons-material/ThumbsUpDownOutlined';

import withRouter from './with-router';
import { DrawerImage } from '../../media';
import { styles } from '../styles';
import { LogoClickable } from '../../components';

const useStyles = makeStyles(() => ({
  drawerPaper: {
    width: styles.drawerWidth,
    backgroundImage: `url(${DrawerImage})`,
    backgroundSize: 'cover',
    backgroundPosition: 'center center',
  },
}));

const SideBar = ({ userId, userType, resetJob, logoutUser }) => {

  const classes = useStyles();
  const navigate = useNavigate();
  const [selectedIndex, setSelectedIndex] = useState(0);

  const handleListItemClick = (event, index) => {
    event.preventDefault()
    setSelectedIndex(index)
  };

  const handleLogout = async (event) => {
    event.preventDefault();
    try {
      await logoutUser();
      navigate('/login');
      await axios.post('/jobs/file-management/deleteFolder', { userId });     
      resetJob(); 
    } catch (error) {
      console.error('Error during logout:', error);
    }
  }

  const DrawerView = withRouter(() =>
    <Drawer
      PaperProps={{ style: { border: 'none', overflowX: 'hidden' }, }}
      variant="permanent"
      classes={{
        paper: classes.drawerPaper,
        borderRight: 0,
      }}
      anchor="left"
    >
      <Grid style={styles.Logo}>
        <LogoClickable />
      </Grid>

      <List>

        <ListItem
          onClick={() => { navigate('/homepage') }}
        >
          <ListItemButton
            sx={styles.SelectedItem}
            selected={selectedIndex === 0}
            onClick={(event) => handleListItemClick(event, 0)}
          >
            <ListItemIcon>
              <HomeIcon style={styles.Icon} />
            </ListItemIcon>
            <ListItemText primary={
              <Typography style={styles.SideBarText}>
                Home
              </Typography>}
            />
          </ListItemButton>
        </ListItem>

        <ListItem
          onClick={() => { navigate('/input') }}
        >
          <ListItemButton
            sx={styles.SelectedItem}
            selected={selectedIndex === 1}
            onClick={(event) => handleListItemClick(event, 1)}
          >
            <ListItemIcon>
              <WorkIcon style={styles.Icon} />
            </ListItemIcon>

            <ListItemText primary={
              <Typography style={styles.SideBarText}>
                Start CGenFF Job
              </Typography>}
            />

          </ListItemButton>
        </ListItem>

        <ListItem
          onClick={() => { navigate('/history') }}
        >
          <ListItemButton
            sx={styles.SelectedItem}
            selected={selectedIndex === 2}
            onClick={(event) => handleListItemClick(event, 2)}
          >
            <ListItemIcon>
              <ListIcon style={styles.Icon} />
            </ListItemIcon>

            <ListItemText primary={
              <Typography style={styles.SideBarText}>
                Job History
              </Typography>}
            />

          </ListItemButton>
        </ListItem>

      </List>

      <List>
        { userType !== 'Academia' ? 
          <ListItem
            onClick={() => { navigate('/pricing') }}
          >
            <ListItemButton
              sx={styles.SelectedItem}
              selected={selectedIndex === 3}
              onClick={(event) => handleListItemClick(event, 3)}
            >
              <ListItemIcon>
                <MonetizationOnIcon style={styles.Icon} />
              </ListItemIcon>

              <ListItemText primary={
                <Typography style={styles.SideBarText}>
                  Pricing
                </Typography>}
              />

            </ListItemButton>
          </ListItem>
          : null 
        }

        <ListItem
          onClick={() => { navigate('/manual') }}
        >
          <ListItemButton
            sx={styles.SelectedItem}
            selected={selectedIndex === 4}
            onClick={(event) => handleListItemClick(event, 4)}
          >
            <ListItemIcon>
              <DescriptionIcon style={styles.Icon} />
            </ListItemIcon>

            <ListItemText primary={
              <Typography style={styles.SideBarText} >
                User Manual
              </Typography>}
            />

          </ListItemButton>
        </ListItem>

        <ListItem
          onClick={() => { navigate('/faq') }}
        >
          <ListItemButton
            sx={styles.SelectedItem}
            selected={selectedIndex === 5}
            onClick={(event) => handleListItemClick(event, 5)}
          >
            <ListItemIcon>
              <QuestionAnswerIcon style={styles.Icon} />
            </ListItemIcon>

            <ListItemText primary={
              <Typography style={styles.SideBarText}>
                FAQ
              </Typography>}
            />

          </ListItemButton>
        </ListItem>
      </List>
      <List>
        <ListItem
          onClick={() => { navigate('/settings') }}
        >
          <ListItemButton
            sx={styles.SelectedItem}
            selected={selectedIndex === 6}
            onClick={(event) => handleListItemClick(event, 6)}
          >
            <ListItemIcon>
              <SettingsIcon style={styles.Icon} />
            </ListItemIcon>

            <ListItemText primary={
              <Typography style={styles.SideBarText} >
                Account Settings
              </Typography>}
            />

          </ListItemButton>
        </ListItem>

        <ListItem
          onClick={() => { navigate('/contact') }}
        >
          <ListItemButton
            sx={styles.SelectedItem}
            selected={selectedIndex === 7}
            onClick={(event) => handleListItemClick(event, 7)}
          >
            <ListItemIcon>
              <EmailIcon style={styles.Icon} />
            </ListItemIcon>

            <ListItemText primary={
              <Typography style={styles.SideBarText} >
                Contact Us
              </Typography>}
            />

          </ListItemButton>
        </ListItem>

        <ListItem
          onClick={() => { navigate('/feedback') }}
        >
          <ListItemButton
            sx={styles.SelectedItem}
            selected={selectedIndex === 8}
            onClick={(event) => handleListItemClick(event, 8)}
          >
            <ListItemIcon>
              <ThumbsUpDownIcon style={styles.Icon} />
            </ListItemIcon>

            <ListItemText primary={
              <Typography style={styles.SideBarText} >
                Feedback
              </Typography>}
            />

          </ListItemButton>
        </ListItem>

      </List>

      <List>

        <ListItem
          onClick={(event) => { handleLogout(event) }}
          key='Logout'
        >
          <ListItemButton
            sx={styles.SelectedItem}
            selected={selectedIndex === 9}
            onClick={(event) => {handleListItemClick(event, 9); }}
          >
            <ListItemIcon>
              <ExitToAppIcon style={styles.Icon} />
            </ListItemIcon>

            <ListItemText primary={
              <Typography style={styles.SideBarText} >
                Logout
              </Typography>}
            />

          </ListItemButton>
        </ListItem>

      </List>

    </Drawer>
  );

  return (
    <DrawerView />
  );

}

export default (SideBar);