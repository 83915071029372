import {
	LOGIN_REQUEST,
	LOGIN_SUCCESS,
	LOGIN_FAILURE,
	LOGOUT_SUCCESS,
	LOGOUT_FAILURE,
	REGISTER_REQUEST,
	REGISTER_SUCCESS,
	REGISTER_FAILURE,
	REGISTER_REQUEST_SUCCESS,
	REGISTER_REQUEST_FAILURE,
	EMAIL_NOT_VERIFIED,
	PW_RESET_FAILURE,
	PW_RESET_SUCCESS,
	PERSIST_FALSE,
	PERSIST_TRUE,
	RESET_AUTH, 
	UPDATE_INFO_FAILURE,
	UPDATE_INFO_SUCCESS,
	RECEIVE_UPDATE_SUCCESS,
	UPDATE_JOB_COUNT,
	UPDATE_FREE_JOBS_COUNT
} from "./auth-types";

const initialState = {
	isAuthenticated: false,
	user: {
		userEmail: "",
		userId: "",
		stripeId: "",
		firstName: "",
		lastName: "",
		company: "",
		userType: "",
		jobCount: "",
		freeCount: "",
		receiveUpdates: true,
	},
	authMsg: "",
	textColor: "white",
}

const authReducer = (state = initialState, action) => {
	switch (action.type) {
		case PERSIST_TRUE:
			return {
				...state,
				isAuthenticated: true,
			};

		case LOGIN_SUCCESS:
			return {
				...state,
				isAuthenticated: true,
				user: {
					...state.user,
					userEmail: action.payload.userEmail,
					userId: action.payload.userId,
					stripeId: action.payload.stripeId,
					firstName: action.payload.firstName,
					lastName: action.payload.lastName,
					company: action.payload.company,
					userType: action.payload.userType,
					jobCount: action.payload.jobCount,
					freeCount: action.payload.freeCount,
					receiveUpdates: action.payload.receiveUpdates,
				},
			};
			
		case UPDATE_INFO_SUCCESS:
			return {
				...state,
				user: {
					...state.user,
					firstName: action.payload.firstName,
					lastName: action.payload.lastName,
					company: action.payload.company,
				},
			}
		
			case UPDATE_JOB_COUNT:
				return {
					...state,
					user: {
						...state.user,
						jobCount: action.payload.jobCount,
					},
				}

			case UPDATE_FREE_JOBS_COUNT:
				return {
					...state,
					user: {
						...state.user,
						freeCount: action.payload.freeCount,
					},
				}

		case UPDATE_INFO_FAILURE:
			return {
				...state,
				authMsg: "Fail to update. Please try again later",
			}

		case RECEIVE_UPDATE_SUCCESS:
			return {
				...state,
				user: {
					...state.user,
					receiveUpdates: action.payload.receiveUpdates,
				},
			}
		

		case EMAIL_NOT_VERIFIED:
		case REGISTER_REQUEST:
		case REGISTER_SUCCESS:
		case REGISTER_FAILURE:
		case REGISTER_REQUEST_SUCCESS:
		case REGISTER_REQUEST_FAILURE:
		case LOGIN_FAILURE:
		case LOGOUT_FAILURE:
		case PW_RESET_SUCCESS:
		case PW_RESET_FAILURE:
			return {
				...initialState,
				authMsg: action.payload.message,
				textColor: action.payload.textColor
			};
		
		case LOGIN_REQUEST:
		case LOGOUT_SUCCESS:
		case PERSIST_FALSE:	
		case RESET_AUTH:
			return {...initialState};

		default:
			return { ...state };
	}
};

export default authReducer;