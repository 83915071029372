import React from 'react';
import { Stage, Component } from 'react-ngl';
import { Typography } from '@mui/material';

const Visualizer = ({ width, height, path, stem, bgcolor, position }) => {

  const defaultReprParams = {
    radiusScale: 0.5,
    aspectRatio: 5,
    quality: 'auto',
    multipleBond: "symmetric"
  };

  return (
    <div>
      <Typography
        variant='h5'
        align="center"
        color='textSecondary'
        style={{ wordWrap: "break-word", wordBreak: 'break-all' }}
      >
        {stem}
      </Typography>

      <Stage
        width={width}
        height={height}
        params={{ backgroundColor: bgcolor }}
      >
        <Component
          path={path}
          reprList={[{ type: 'ball+stick', params: defaultReprParams }]}
          position={[-position[0], -position[1], -position[2]]}
          scale={2.5}
        />
      </Stage>
    </div>
  );
};

export default Visualizer;
