import React from 'react';
import { connect } from 'react-redux';
import { Outlet, Navigate } from 'react-router-dom';
import { persist } from '../../redux/auth/auth-actions';

const PrivateRoute = ({ isAuthenticated, persist }) => {
  persist()
  return isAuthenticated ? <Outlet /> : <Navigate to="/login" />;
};


const mapStateToProps = state => {
  return {
    isAuthenticated: state.auth.isAuthenticated
  }
}

const mapDispatchToProps = (dispatch) => {
	return {
		persist: () => dispatch(persist()),
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(PrivateRoute);