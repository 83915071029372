// Login
export const LOGIN_REQUEST = 'LOGIN_REQUEST';
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS';
export const LOGIN_FAILURE = 'LOGIN_FAILURE';
export const EMAIL_NOT_VERIFIED = "EMAIL_NOT_VERIFIED";

// Persist
export const PERSIST_TRUE  = 'PERSIST_TRUE';
export const PERSIST_FALSE = 'PERSIST_FALSE';

// Logout
export const LOGOUT_SUCCESS = 'LOGOUT_SUCCESS';
export const LOGOUT_FAILURE = 'LOGOUT_FAILURE'; 

// Register
export const REGISTER_REQUEST = 'REGISTER_REQUEST'
export const REGISTER_REQUEST_SUCCESS = 'REGISTER_REQUEST_SUCCESS';
export const REGISTER_REQUEST_FAILURE = 'REGISTER_REQUEST_FAILURE';
export const REGISTER_SUCCESS = 'REGISTER_SUCCESS';
export const REGISTER_FAILURE = 'REGISTER_FAILURE';

// Update info
export const UPDATE_INFO_SUCCESS = 'UPDATE_INFO_SUCCESS';
export const UPDATE_INFO_FAILURE = 'UPDATE_INFO_FAILURE';
export const UPDATE_JOB_COUNT    = 'UPDATE_JOB_COUNT'
export const UPDATE_FREE_JOBS_COUNT = 'UPDATE_FREE_JOBS_COUNT';


// Reset password
export const PW_RESET_SUCCESS = 'PW_RESET_SUCCESS';
export const PW_RESET_FAILURE = 'PW_RESET_FAILURE';

// errors
export const CLEAR_LOGIN_ERROR = 'CLEAR_ERROR';
export const RESET_AUTH = 'RESET_AUTH'

// payment
export const CREATE_PAYMENT_ACCCOUNT = 'CREATE_PAYMENT_ACCCOUNT';

// receive update
export const RECEIVE_UPDATE_SUCCESS = 'RECEIVE_UPDATE_SUCCESS';