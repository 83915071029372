import React, { useState } from "react";
import { Grid, Typography, Paper, Button, Box } from "@mui/material";
import { styles } from "../../../app";
import { FeedbackFormModal } from "../../feedback";

const NewsAndUpadtes = ({ userType }) => {
  const [open, setOpen] = useState(false);

  let message;
  if (userType === "Academia") {
    message = `
      SilcsBio is excited to announce the launch of our CGenFF web app! 
      In our commitment to supporting academic excellence and innovation, this tool will be available to all academic users at no cost. 
      Your experience and insights are crucial to us; please share your feedback through our dedicated form.
    `
  } else {
    message = `
      SilcsBio proudly introduces the commercial launch of our CGenFF web app! 
      Tailored for drug design professionals, it streamlines small molecule parametrization. 
      Your experience and insights are crucial to us; please share your feedback through our dedicated form.
    `
  }


  return (
    <Paper style={{ padding: "20px", flexDirection: "column", alignItems: "center" }}>
      <Typography padding={4} style={{ height: "250px" }}>
        <Grid item xs style={styles.Title}>
          NEWS AND UPDATES
        </Grid>

        <Typography align="justify" marginTop="20px">
          {message}
        </Typography>

        <Box display="flex" justifyContent="center">
          <Button onClick={() => setOpen(true)} variant="contained" color="primary" style={{ margin: 20 }}>
            PROVIDE FEEDBACK
          </Button>
        </Box>
      </Typography>

      <FeedbackFormModal open={open} onClose={() => setOpen(false)} setOpen={setOpen} />
    </Paper>
  );
};

export default NewsAndUpadtes;
