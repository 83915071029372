import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { connect } from "react-redux";
import axios from "axios";

import { makeStyles } from "@mui/styles";
import { Box, Button, Grid, Typography } from "@mui/material";
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';

import { Visualizer, JobOptions } from "./utils"
import { saveJobName, saveJobOptions, jobFailed, resetJob, saveMol2FileName, saveCenter } from "../../redux/job/job-actions";

import { LoadingDna } from "../../components";
import { UploadMol2 } from "./utils";
import { BetaBanner } from '../../components'

const useStyles = makeStyles(() => ({
  paper: {
    display: 'flex',
    flexWrap: 'noWrap',
    alignItems: "center",
    alignContent: "center",
    justifyContent: "center",
  },
  submitBox: {
    display: "flex",
    justifyContent: "center",
    gap: '20px'
  }
}));

const CGenFFOptions = ({ userId, jobId, userType, jobCount, fileName, stem, saveJobName, saveJobOptions, jobFailed, saveMol2FileName, saveCenter, centerx, centery, centerz }) => {

  const navigate = useNavigate();

  const classes = useStyles();
  const [jobName, setJobName] = useState(null)
  const [buttonDisabled, setButtonDisabled] = useState([false, false])
  const [errors, setErrors] = useState(null)
  const [jobOptionState, setJobOptionState] = useState({
    mol2out: true,
    warning: true,
    debug: true,
    copyParams: false
  })
  const [jobOptionDisabled, setJobOptionDisabled] = useState(false)
  const [buttonStr, setButtonStr] = useState('Process Input')
  const [isLoading, setIsLoading] = useState(true)
  const [open, setOpen] = useState(false);

  const bgcolor = "#deeafc"
  const path = `./build/${userId}/${jobId}/${fileName}`

  const startOver = () => {
    setButtonDisabled([false, false])
    setJobName(null)
    setErrors(null)
    setButtonStr('Process Input')
    setOpen(false)
    setJobOptionDisabled(false)
    jobFailed(false)
  }

  const handleOptionsChange = (e) => {
    setJobOptionState({
      ...jobOptionState,
      [e.target.name]: e.target.checked
    });
  }

  const previousStep = (e) => {
    if (e) e.preventDefault();
    resetJob()
    setOpen(true)
  }

  const nextStep = (e) => {
    if (e) e.preventDefault();
    saveJobName(jobName)
    setButtonStr('Processing...')
    setJobOptionDisabled(true)
    setButtonDisabled([true, true])
    axios.post('/job-managers/submitCGenFF', {
      'userId': userId,
      'jobId': jobId,
      'stem': stem,
      'options': jobOptionState
    })
      .then((res) => {
        if (res.data === 'bad molecule') {
          jobFailed(true)
          navigate("/checkout")
        } else if (res.status === 200) {
          navigate("/checkout")
        }
      })
      .catch((err) => {
        if (err.response.data === 'CGenFF') {
          jobFailed(true)
          navigate("/checkout")
        }
        if (err.response.data === 'GRMX') {
          jobFailed(true)
          setButtonDisabled([false, true])
          setErrors('There is an error generating the parameters in GROMACS format. Try reuploading the Mol2 file and deselecting that option.')
        }
        if (err.response.data === 'fileName') {
          jobFailed(true)
          setButtonDisabled([false, true])
          setErrors(`Please remove any special characters from the file name. They can be "(, ), *, /,..."`)
        }
      })
  }

  useEffect(() => {
    setIsLoading(false)
  }, [centerx, centery, centerz])

  useEffect(() => {
    startOver()
  }, [jobId])

  return (
    <div className={classes.paper}>

      {isLoading ? <LoadingDna /> :

        <Box sx={{ width: '1150px', background: 'white' }}>

          <Grid container className={classes.paper}>
            <Grid item xs={12} paddingBottom={5} style={{ display: 'flex', justifyContent: 'center' }}>
            <BetaBanner />
          </Grid>

            <Grid item xs={6} padding={2}>
              <Visualizer
                width={'500px'}
                height={'70vh'}
                path={path}
                bgcolor={bgcolor}
                stem={stem}
                position={[centerx, centery, centerz]}
              />
            </Grid>

            <Grid item xs={6}>
              <JobOptions
                stem={stem}
                setJobName={setJobName}
                handleOptionsChange={handleOptionsChange}
                jobOptionDisabled={jobOptionDisabled}
                saveJobOptions={saveJobOptions}
                jobOptionState={jobOptionState}
              />

              <Typography align="center" color='red'>
                {errors}
              </Typography>

              <Box textAlign="center" m={4} className={classes.submitBox} >
                <Button
                  onClick={previousStep}
                  variant="contained"
                  color="primary"
                  disabled={buttonDisabled[0]}
                >
                  <ArrowBackIosNewIcon /> Change Input
                </Button>

                <Button
                  onClick={() => nextStep()}
                  variant="contained"
                  color="primary"
                  disabled={buttonDisabled[1]}
                >
                  {buttonStr} <ArrowForwardIosIcon />
                </Button>

              </Box>
            </Grid>
          </Grid>
        </Box>


      }

      <UploadMol2
        userId={userId}
        userType={userType}
        jobCount={jobCount}
        open={open}
        setOpen={setOpen}
        saveMol2FileName={saveMol2FileName}
        saveCenter={saveCenter}
      />

    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    userId: state.auth.user.userId,
    userType: state.auth.user.userType,
    jobCount: state.auth.user.jobCount,
    fileName: state.job.jobInfo.fileName,
    stem: state.job.jobInfo.stem,
    jobId: state.job.jobInfo.jobId,
    centerx: state.job.jobInfo.centerx,
    centery: state.job.jobInfo.centery,
    centerz: state.job.jobInfo.centerz
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    saveJobName: (jobName) => dispatch(saveJobName(jobName)),
    saveJobOptions: (e) => dispatch(saveJobOptions(e)),
    jobFailed: (value) => dispatch(jobFailed(value)),
    saveMol2FileName: (fileName, jobId) => dispatch(saveMol2FileName(fileName, jobId)),
    resetJob: () => dispatch(resetJob()),
    saveCenter: (com) => dispatch(saveCenter(com))
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(CGenFFOptions);