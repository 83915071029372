import React, { useState } from "react";
import { connect } from "react-redux";

import { Typography, Paper, Grid, Button } from "@mui/material";

import { Background, tutorialStepsIndustry, tutorialStepsAcademia, TutorialCarousel } from './utils'
import { styles } from "../../app";
import { BetaBanner } from "../../components";

const UserManual = ({userType}) => {

	const [open, setOpen] = useState(false)

	const handleClickOpen = () => {
		setOpen(true);
	};

	let tutorialSteps;
	if (userType === 'Academia') {
		tutorialSteps = tutorialStepsAcademia;
	} else {
		tutorialSteps = tutorialStepsIndustry;
	}

	return (
		<div>
			<Grid item xs={12} paddingBottom={5} style={{ display: 'flex', justifyContent: 'center' }}>
        <BetaBanner />
      </Grid>
		<Paper style={{
			padding: '20px', minWidth: "900px", maxWidth: "1100px", display: 'flex',
			flexDirection: 'column',
			alignItems: "center",
		}}>

			<Typography padding={4}>

				<Grid container alignItems="center" justifyContent="center" spacing={4}>

					<Grid item xs={12} style={styles.Title}>
						USER MANUAL
					</Grid>

					<div>
						<Button variant="contained" onClick={handleClickOpen}
							style={{ width: '200pt', height: '40pt', fontSize: '25px', fontWeight: 'bold' }}
						>
							Start Tutorial
						</Button>
					</div>

					<Grid item xs={12} style={styles.SubTitle}>
						CGenFF: CHARMM General Force Field
					</Grid>

				</Grid>

				<div style={{ paddingTop: '30px', marginLeft: '60px', marginRight: '60px' }}>
					<Background />
				</div>

			</Typography>

			<TutorialCarousel
				open={open}
				setOpen={setOpen}
				tutorialSteps={tutorialSteps}
			/>

		</Paper>
		</div>

	);
}

const mapStateToProps = (state) => ({
  userType: state.auth.user.userType,
});

export default connect(mapStateToProps)(UserManual);

