import React, { useState } from "react";
import { connect } from "react-redux";
import axios from "axios";
import {
  Alert,
  Typography,
  TextField,
  Grid,
  Paper,
  Button,
  InputLabel,
  Snackbar,
  RadioGroup,
  Radio,
  FormControlLabel,
} from "@mui/material";

import { styles } from "../../app";
import { BetaBanner } from "../../components";

import MarkEmailReadOutlinedIcon from "@mui/icons-material/MarkEmailReadOutlined";

const FeedbackForm = ({ userEmail, firstName, lastName, onClose }) => {
  const [name, setName] = useState(`${firstName} ${lastName}`);
  const [email, setEmail] = useState(userEmail);
  const [easeOfUse, setEaseOfUse] = useState('5'); // Default to 3 (Neutral)
  const [problemsEncountered, setProblemsEncountered] = useState('');
  const [additionalComments, setAdditionalComments] = useState('');
  const [featureComments, setFeatureComments] = useState('');
  const [errors, setErrors] = useState('');
  const [showAlert, setShowAlert] = useState(false);

  const handleClose = () => setShowAlert(false);

  const resetForm = () => {
    setName(`${firstName} ${lastName}`);
    setEmail(userEmail);
    setEaseOfUse('3');
    setProblemsEncountered('');
    setFeatureComments('');
    setAdditionalComments('');
  };

  const feedbackFormData = {
    senderEmail: email,
    senderIdentity: name,
    feedbackMessage: `
      How easy did you find The CGenFF web app to use? ${easeOfUse}
      
      Did you run into any problems while using the web app? If so, please explain: ${problemsEncountered}
      
      Is there any feature you'd like to see implemented in future releases? ${featureComments}
      
      Additional Comments: ${additionalComments}
    `,
  };

  const handleClick = () => {
    if (!problemsEncountered) {
      setErrors("Please provide feedback for the required questions (Marked with *)");
    } else {
      setErrors('');
      axios.post('/feedbackFromWebApp', {
        feedbackFormData
      }).then(() => {
        setShowAlert(true);
        resetForm();
        if (typeof onClose === 'function') {
          setTimeout(() => {
            onClose();
          }, 2000)
        }
      });
    }
  };

  return (
    <div>
      <Grid item xs={12} paddingBottom={5} style={{ display: 'flex', justifyContent: 'center' }}>
        <BetaBanner />
      </Grid>
      <Paper style={{ padding: '40px', width: "750", display: 'flex', flexDirection: 'column', alignItems: "center" }}>
        <Typography padding={4}>
          <Grid container style={{ width: "750px" }} spacing={4}>
            <Grid item xs={12}>
              <Grid item style={styles.Title}>
                HOW ARE WE DOING?
              </Grid>
              <Typography variant="h7" paragraph>
                We appreciate any feedback to help us deliver a better customer experience.
              </Typography>
            </Grid>

            <Grid item container xs={12} spacing={4}>

              {[
                {
                  label: "*How easy did you find the CGenFF web app to use?",
                  value: easeOfUse,
                  onChange: e => setEaseOfUse(e.target.value),
                  type: "radio",
                  options: [
                    { label: '1', value: '1', placement: 'top' },
                    { label: '2', value: '2', placement: 'top' },
                    { label: '3', value: '3', placement: 'top' },
                    { label: '4', value: '4', placement: 'top' },
                    { label: '5', value: '5', placement: 'top' },
                  ],
                },
                { label: "*Did you run into any problems while using the web app? If so, please explain.", value: problemsEncountered, onChange: e => setProblemsEncountered(e.target.value), multiline: true, maxLength: 1000 },
                { label: "Is there any feature you'd like to see implemented in future releases?", value: featureComments, onChange: e => setFeatureComments(e.target.value), multiline: true, maxLength: 1000 },
                { label: "Any additional comments?", value: additionalComments, onChange: e => setAdditionalComments(e.target.value), multiline: true, maxLength: 1000 },
              ].map(({ label, value, type, options, onChange, multiline, maxLength }, index) => (
                <Grid key={index} item xs={12} paddingBottom={index === 3 ? 4 : 0}>
                  <InputLabel shrink={false} htmlFor={label.toLowerCase().replace(/\s+/g, "")}>
                    <Typography styles={{ wordBreak: "break-word" }}>{label}</Typography>
                  </InputLabel>

                  {type === "radio" ? (
                    <RadioGroup
                      row
                      id={label.toLowerCase().replace(/\s+/g, "")}
                      value={value}
                      onChange={onChange}
                      style={{ justifyContent: 'center' }}
                    >
                      <Typography paddingTop={2}>
                        Very difficult to use
                        {options.map((option) => (
                          <FormControlLabel key={option.value} value={option.value} control={<Radio />} label={option.label} labelPlacement={option.placement} />
                        ))}
                        Very easy to use
                      </Typography>
                    </RadioGroup>
                  ) : (
                    <TextField
                      required
                      id={label.toLowerCase().replace(/\s+/g, "")}
                      fullWidth
                      margin="normal"
                      name={label.toLowerCase().replace(/\s+/g, "")}
                      value={value}
                      variant="outlined"
                      onChange={onChange}
                      multiline={multiline}
                      inputProps={{ maxLength }}
                    />
                  )}
                </Grid>
              ))}


              <Grid container justifyContent="center">
                <Grid item xs={12} style={{ textAlign: 'center' }}>
                  <Typography color='error'>{errors}</Typography>
                </Grid>

                <Grid item xs={12} style={{ textAlign: 'center' }} paddingTop={2}>
                  <Button
                    type="submit"
                    variant="contained"
                    color="primary"
                    size='small'
                    onClick={handleClick}
                    style={{ marginBottom: '20px' }}
                  >
                    SUBMIT FEEDBACK
                  </Button>
                </Grid>
              </Grid>

              <Snackbar
                autoHideDuration={5000}
                anchorOrigin={{ vertical: "top", horizontal: "center" }}
                open={showAlert}
                onClose={handleClose}
                PaperProps={{ sx: { boxShadow: "None" } }}
              >
                <Alert sx={{ boxShadow: "None" }} onClose={handleClose} severity="success" icon={<MarkEmailReadOutlinedIcon />} >
                  Feedback submitted successfully. Thank you for your input!
                </Alert>
              </Snackbar>

            </Grid>
          </Grid>
        </Typography>
      </Paper>
    </div>
  );
};

const mapStateToProps = (state) => ({
  userEmail: state.auth.user.userEmail,
  firstName: state.auth.user.firstName,
  lastName: state.auth.user.lastName,
});

export default connect(mapStateToProps)(FeedbackForm);