// import { Box, Typography } from "@mui/material";

const BetaBanner = () => {
  // return (
  //   <Box bgcolor='#3457a7' padding={2} sx={{ width: '900px' }} PaperProps={{ sx: { boxShadow: "None" } }}>
  //     <Typography variant="h5" color="white" align="center" >
  //       The CGenFF web app is currently in beta mode
  //     </Typography>
  //   </Box>
  // )
}
export default BetaBanner