import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  Avatar,
  Button,
  TextField,
  Link,
  Typography,
  Stack,
  Box,
} from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2'
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import { makeStyles } from '@mui/styles';
import { UseForm, Validate } from './index';
import { styles } from '../../../app/styles';

const useStyles = makeStyles(() => ({
  error: {
    color: 'red',
    fontSize: 16,
    fontFamily: 'Montserrat Variable',
    textAlign: 'center',
  },
}));

const Signin = ({
  isAuthenticated,
  loginUser,
  resetPassword,
  authMsg,
  resetAuth,
  redirect,
  registerRequest,
  textColor,
}) => {
  const classes = useStyles();
  const navigate = useNavigate();

  const [newUser, setNewUser] = useState(false);
  const [resetPW, setResetPW] = useState(false);

  const login = () => {
    if (resetPW) {
      resetPassword(credentials.email, credentials.password);
    } else {
      loginUser(credentials.email, credentials.password);
    }
  };

  useEffect(() => {
    if (isAuthenticated) {
      navigate('/homepage');
    }
  }, [isAuthenticated]);

  const [credentials, handleChange, handleSubmit, errors, isSubmitting] = UseForm(
    login,
    Validate,
    resetPW,
    newUser,
    redirect
  );

  return (
    <Box sx={{ flexGrow: 1 }}>
      <Grid container spacing={5} direction="column" alignItems="center" justifyContent="center">
        <Grid container item spacing={3}>
          <Avatar sx={{ bgcolor: 'primary.main' }}>
            <LockOutlinedIcon />
          </Avatar>
        </Grid>
        <Grid container item spacing={3}>
          <Typography style={styles.Title}>
            {resetPW ? 'Reset Password' : 'LOG IN'}
          </Typography>
        </Grid>

        {/* <Typography align="center" variant="body1" sx={{ color: 'primary.main', fontWeight: 'bold' }} paddingBottom={2} paddingLeft={3} paddingRight={3}>
          The CGenFF web app is currently in beta mode
        </Typography> */}

        {authMsg && (
          <Typography align="center" variant="body1" sx={{ color: textColor }} paddingBottom={2}>
            {authMsg}
          </Typography>
        )}

        <Grid item xs={12}>
          <TextField
            focused
            color="grey"
            type="email"
            id="email"
            name="email"
            variant="outlined"
            required
            fullWidth
            label="Email Address"
            placeholder="Email Address"
            value={credentials.email}
            onChange={handleChange}
            autoComplete="email"
            inputProps={{ maxLength: 50 }}
            className={(errors.emailIsEmpty || errors.emailFormatInvalid) && 'input-error'}
          />

          {errors.emailIsEmpty && <small className={classes.error}>{errors.emailIsEmpty}</small>}
          {errors.emailFormatInvalid && (
            <small className={classes.error}>{errors.emailFormatInvalid}</small>
          )}
        </Grid>

        {!resetPW && (
          <Grid item xs={12}>
            <TextField
              focused
              color="grey"
              value={credentials.password}
              onChange={handleChange}
              variant="outlined"
              required
              fullWidth
              name="password"
              label="Password"
              type="password"
              id="password"
              autoComplete="password"
              placeholder="Password"
              inputProps={{ maxLength: 50 }}
              className={(errors.passIsStrong || errors.passIsEmpty) && 'input-error'}
            />

            {errors.passIsEmpty && <small className={classes.error}>{errors.passIsEmpty}</small>}
            {errors.passIsStrong && <small className={classes.error}>{errors.passIsStrong}</small>}
          </Grid>
        )}

        <Grid item xs={7}>
          <Button
            onClick={(e) => {
              handleSubmit(e);
            }}
            type="submit"
            fullWidth
            variant="contained"
            color="primary"
          >
            {resetPW ? 'Reset' : isSubmitting ? 'Logging in ...' : 'Login'}
          </Button>
        </Grid>

        {!resetPW && (
          <Grid item xs={12}>
            <Stack direction="row" justifyContent="end">
              <Link
                href="#"
                variant="body2"
                onClick={() => {
                  resetAuth();
                  setResetPW(true);
                  setNewUser(false);
                }}
              >
                <Typography>Forgot password?</Typography>
              </Link>
            </Stack>
          </Grid>
        )}

        {resetPW && (
          <Grid item xs={12}>
            <Stack direction="row" justifyContent="end">
              <Link
                href="#"
                variant="body2"
                onClick={() => {
                  setResetPW(false);
                  setNewUser(false);
                  resetAuth();
                }}
              >
                <Typography>Already have an account? Login</Typography>
              </Link>
            </Stack>
          </Grid>
        )}

        <Grid item xs={12}>
          <Stack direction="row" justifyContent="end" alignItems="center" spacing={2}>
            <Typography fontWeight="bold">Not a user?</Typography>
            <Button
              type="submit"
              variant="outlined"
              color="primary"
              size="small"
              onClick={() => {
                resetAuth();
                registerRequest();
                setNewUser(true);
                if (resetPW) setResetPW(false);
                navigate('/signup');
              }}
            >
              Create Account
            </Button>
          </Stack>
        </Grid>
      </Grid>
    </Box>
  );
};

export default Signin;
