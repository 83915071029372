import { useState, useEffect } from "react";
import _ from "lodash";

const useForm = (loginFunction, validate, isReset, isNewUser, isPassValid) => {
  const [errors, setErrors] = useState({});
  const [credentials, setCredentials] = useState({
    email: "",
    password: "",
    confirmPassword: "",
    firstName: "",
    lastName: "",
    jobTitle: "",
    country: "US",
    userType: "Academia",
    agreedToTerms: false,
  });
  const [isSubmitting, setIsSubmitting] = useState(false);

  useEffect(() => {
    if (_.isEmpty(errors) && isSubmitting) {
      loginFunction();
      setCredentials({
        email: "",
        password: "",
        confirmPassword: "",
        firstName: "",
        lastName: "",
        jobTitle: "",
        country: "US",
        userType: "Academia",
        agreedToTerms: false,
      });
      setErrors({});
      setIsSubmitting(false);
    } else {
      setIsSubmitting(false);
    }
  }, [isSubmitting, errors, loginFunction]);

  const handleSubmit = (e) => {
    e.preventDefault();
    const validationErrors = validate(
      credentials,
      isReset,
      isNewUser,
      isPassValid
    );
    setErrors(validationErrors);
    setIsSubmitting(true);
  };

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    setCredentials((prev) => ({
      ...prev,
      [name]: type === "checkbox" ? checked : value,
    }));
  };

  return [credentials, handleChange, handleSubmit, errors];
};

export default useForm;
