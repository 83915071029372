import { AppLogo } from "../media";
import { ButtonBase, Link } from "@mui/material";
import { styles } from "../app";

const LogoClickable = () => {
  
  return (
    <Link href="https://cgenff.com" target="_blank">
      <ButtonBase>
        <img style={{ backgroundColor: 'white', padding: '35px' }} alt="" src={AppLogo} width={styles.logoWidth}/>
      </ButtonBase>
    </Link>
  )
}
export default LogoClickable