import React, { useState, useEffect } from "react";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import { loadStripe } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";
import CheckoutButton from "./checkout-button";
import axios from "axios";

// Make sure to call loadStripe outside of a component’s render to avoid
// recreating the Stripe object on every render.
const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_KEY);

const CheckoutPopUp = ({ userId, jobId, stripeId, userEmail, open, setOpen, setIsPaid, uploadResult, updateJobCounts }) => {
  const [clientSecret, setClientSecret] = useState('');
  const [customer, setCustomer] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchCustomerData = async (stripeId) => {
      try {
        // Create PaymentIntent and retrieve Customer Account
        const [intentResponse, customerResponse] = await Promise.all([
          axios.post("/payment/create-intent", { stripeId }),
          axios.post("/payment/get", { stripeId }),
        ]);

        setClientSecret(intentResponse.data.clientSecret);
        setCustomer(customerResponse.data);
        setLoading(false);
      } catch (error) {
        console.error("Error fetching data:", error);
        setLoading(false);
      }
    };

    fetchCustomerData(stripeId);
  }, [stripeId]);

  const appearance = {
    theme: 'stripe',
  };

  const options = {
    clientSecret,
    appearance,
  };

  return (
    <Dialog
      open={open}
      onClose={() => setOpen(false)}
      PaperProps={{
        sx: {
          boxShadow: 'None',
        },
      }}
    >
      <DialogActions>
        {!loading && clientSecret && (
          <Elements options={options} stripe={stripePromise}>
            <CheckoutButton
              userId={userId}
              jobId={jobId}
              setOpen={setOpen}
              setIsPaid={setIsPaid}
              userEmail={userEmail}
              stripeId={stripeId}
              uploadResult={uploadResult}
              updateJobCounts={updateJobCounts}
              name={customer?.name}
              line1={customer?.address?.line1}
              line2={customer?.address?.line2}
              city={customer?.address?.city}
              state={customer?.address?.state}
              postal_code={customer?.address?.postal_code}
              country={customer?.address?.country}
              phone={customer?.phone}
            />
          </Elements>
        )}
      </DialogActions>
    </Dialog>
  );
};

export default CheckoutPopUp;
