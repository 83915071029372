import React from "react";

import { makeStyles } from "@mui/styles"
import { useTheme } from "@mui/material/styles"
import { Paper, Grid, MobileStepper, Typography, Button } from "@mui/material";
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import SwipeableViews from "react-swipeable-views";
import { autoPlay } from "react-swipeable-views-utils";

const AutoPlaySwipeableViews = autoPlay(SwipeableViews);

const useStyles = makeStyles(() => ({
  img: {
    display: 'flex',
    justifyContent: 'center',
  },
  header: {
    display: "flex",
    alignItems: "center",
    maxHeight: '500dp',
    paddingTop: '20px'
  },
}));

const Carousel = ({ tutorialSteps }) => {
  const classes = useStyles();
  const theme = useTheme();
  const [activeStep, setActiveStep] = React.useState(0);
  const maxSteps = tutorialSteps.length;

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleStepChange = (step) => {
    setActiveStep(step);
  };

  return (
    <>
      <AutoPlaySwipeableViews
        animateHeight
        axis={theme.direction === "rtl" ? "x-reverse" : "x"}
        index={activeStep}
        onChangeIndex={handleStepChange}
        enableMouseEvents
        interval={500000}
        autoPlay={false}
      >
        {tutorialSteps.map((step, index) => (
          <div key={step.label} className={classes.img}>
            {Math.abs(activeStep - index) <= 2 ? (
              <img
                src={step.imgPath}
                alt={step.label}
                width={step.width}
                height={step.height}
              />
            ) : null}
          </div>
        ))}
      </AutoPlaySwipeableViews>
      <Paper elevation={0} className={classes.header} sx={{ boxShadow: 'None' }}>
        <Grid container direction="column" alignItems="center">
          <Typography variant="h6" gutterBottom>
            {tutorialSteps[activeStep].label}
          </Typography>
          <Grid
            container
            justify="center"
            style={{ padding: "0px 24px 0px 24px" }}
          >
            <Typography variant="body1">
              {tutorialSteps[activeStep].description}
            </Typography>
          </Grid>
        </Grid>
      </Paper>
      <MobileStepper
        steps={maxSteps}
        position="static"
        variant="dots"
        activeStep={activeStep}
        nextButton={
          <Button
            size="small"
            onClick={handleNext}
            disabled={activeStep === maxSteps - 1}
          >
            Next
            {theme.direction === "rtl" ? (
              <KeyboardArrowLeftIcon />
            ) : (
              <KeyboardArrowRightIcon />
            )}
          </Button>
        }
        backButton={
          <Button size="small" onClick={handleBack} disabled={activeStep === 0}>
            {theme.direction === "rtl" ? (
              <KeyboardArrowRightIcon />
            ) : (
              <KeyboardArrowLeftIcon />
            )}
            Back
          </Button>
        }
      />
    </>
  );
}

export default Carousel;