import React from 'react';
import { Dialog } from '@mui/material';

import FeedbackForm from './feedback.js';

const FeedbackFormModal = ({ open, onClose }) => {

  return (
    <Dialog
      open={open}
      onClose={onClose}
      closeAfterTransition
      maxWidth='md'
      PaperProps={{ sx: { boxShadow: "None" } }}
    >
      <FeedbackForm onClose={onClose}/>
    </Dialog>
  );
};

export default FeedbackFormModal;
