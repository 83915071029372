import React, { useEffect, useState } from 'react';
import Parser from "html-react-parser";
import { useNavigate } from 'react-router-dom';
import {
  Avatar,
  Button,
  TextField,
  MenuItem,
  Grid,
  FormControlLabel,
  Typography,
  FormGroup,
  Checkbox,
  Tooltip,
  Paper,
  Stack,
  Box,
  Radio,
  RadioGroup,
  FormLabel, 
  Dialog
} from '@mui/material';

import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import { makeStyles } from '@mui/styles';

import countryList from 'react-select-country-list';
import PasswordChecklist from 'react-password-checklist';
import { connect } from 'react-redux';

import { registerUser, resetAuth } from '../../redux/auth/auth-actions'
import { UseForm, Validate } from './utils';
import { Header } from '../../app/utils';
import { styles } from '../../app/styles';

import TCpdf from "./utils/CGENFF_CSA_V1.pdf";


const useStyles = makeStyles(() => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    maxWidth: '500px',
    minWidth: '350px',
    padding: '50px',
  },
  option: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center'
  },
  error: {
    color: 'red',
    fontSize: '16',
    fontFamily: 'Montserrat Variable',
    textAlign: 'center'
  },
}));

const Signup = ({
  registerUser,
  authMsg,
  textColor,
  resetAuth
}) => {

  const classes = useStyles();
  const navigate = useNavigate();

  let reset = false;
  let newUser = true;

  const [isPassValid, setIsPassValid] = useState(false);
  const [credentials, handleChange, handleSubmit, errors] = UseForm(register, Validate, reset, newUser, isPassValid);
  const [showMessage, setShowMessage] = useState(false);
  const [checked, setChecked] = useState(false)
  const [isViewed, setIsViewed] = useState(false);
  const [buttonStr, setButtonStr] = useState('Create Account');
  const [show, setShow] = useState(false)

  const handleClose = () => setShowMessage(false);

  
  useEffect(() => {

    if (authMsg) {
      setShowMessage(true);
    }
    
    setChecked(false);
  
    const handleSuccess = () => {
      setButtonStr('CREATING ACCOUNT...');
      setTimeout(() => {  
        resetAuth();
        navigate('/login');
      }, 10000);
    };
  
    const handleFailure = () => {
      setTimeout(() => {
        resetAuth();
        window.location.reload();
      }, 7000);
    };

    textColor === 'green' ? handleSuccess() : textColor === 'red' && handleFailure();
    
  }, [authMsg, textColor]);

  function register() {
    if (newUser) {
      registerUser(credentials)
    }
  }

  return (
    <div style={{ padding: '100px' }}>

      <Header />

      <Paper className={classes.root}>

        <Avatar sx={{ bgcolor: 'primary.main' }}>
          <LockOutlinedIcon />
        </Avatar>

        <Typography style={styles.Title}>
          SIGN UP
        </Typography>

        {/* <Typography align="center" variant="body1" sx={{ color: 'primary.main', fontWeight: 'bold' }} paddingBottom={2} paddingLeft={3} paddingRight={3}>
          The CGenFF web app is currently in beta mode
        </Typography> */}

        <Dialog
          open={showMessage}
          onClose={handleClose}
          PaperProps={{ sx: { boxShadow: "None" } }}
        >
          <Typography align="center" variant="body1" sx={{ color: textColor }} padding={2}>
            {authMsg}
            {textColor === 'green' ? " Redirecting to home page..." : " Refreshing page..."}
          </Typography>  
        </Dialog>

        <Grid container spacing={3}>

          <Grid item xs={12}>
            <FormLabel>Select Account Type *:</FormLabel>
          </Grid>
          <Grid item xs={12} className={classes.option}>
            <RadioGroup
              row
              name="userType"
              value={credentials.userType}
              onChange={handleChange}
            >
              <FormControlLabel value="Academia" control={<Radio />} label="Academia" onChange={handleChange} />
              <FormControlLabel value="Industry" control={<Radio />} label="Industry" onChange={handleChange} />
              {/* <FormControlLabel value="Government" control={<Radio />} label="Government" onChange={handleChange} /> */}
            </RadioGroup>
          </Grid>


          <Grid item xs={12}>
            <TextField
              focused
              color='grey'
              type="email"
              id="email"
              name="email"
              value={credentials.email}
              onChange={handleChange}
              variant="outlined"
              margin="normal"
              fullWidth
              required
              label="Email Address"
              autoComplete="email"
              inputProps={{ maxLength: 50 }}
              className={(errors.emailIsEmpty || errors.emailFormatInvalid) && "input-error"}
            />
            {/* Email errors */}
            {errors.emailIsEmpty &&
              <small className={classes.error}>{errors.emailIsEmpty}</small>
            }
            {errors.emailFormatInvalid &&
              <small className={classes.error}>{errors.emailFormatInvalid}</small>
            }
            {errors.emailIsNotEDU &&
              <small className={classes.error}>{React.createElement("small", null, Parser(errors.emailIsNotEDU))}</small>
            }
          </Grid>

          <Grid item xs={6}>
            <TextField
              focused
              color='grey'
              variant="outlined"
              required
              fullWidth
              id="firstName"
              label="First Name"
              name="firstName"
              value={credentials.firstName}
              type="text"
              inputProps={{
                autoCapitalize: 'words',
                maxLength: 50
              }}
              onChange={handleChange}
              className={(errors.firstNameIsEmpty) && "input-error"}
            />
            {/* First Name Errors */}
            {errors.firstNameIsEmpty &&
              <small className={classes.error}>{errors.firstNameIsEmpty}</small>
            }
          </Grid>

          <Grid item xs={6}>
            <TextField
              focused
              color='grey'
              variant="outlined"
              required
              fullWidth
              id="lastName"
              label="Last Name"
              name="lastName"
              value={credentials.lastName}
              onChange={handleChange}
              inputProps={{ maxLength: 50 }}
              className={(errors.lastNameIsEmpty) && "input-error"}
            />
            {/* Last Name Errors */}
            {errors.lastNameIsEmpty &&
              <small className={classes.error}>{errors.lastNameIsEmpty}</small>
            }
          </Grid>

          <Grid item xs={6}>
            <TextField
              color='grey'
              focused
              defaultValue=""
              fullWidth
              value={credentials.jobTitle}
              label={credentials.userType === 'Academia' ? "Institution Name *" : "Company Name *"}
              onChange={handleChange}
              variant="outlined"
              name="jobTitle"
              inputProps={{ maxLength: 50 }}
              className={(errors.jobTitleIsEmpty) && "input-error"}
            />
            {/* Company Errors */}
            {errors.jobTitleIsEmpty &&
              <small className={classes.error}>{errors.jobTitleIsEmpty}</small>
            }
          </Grid>

          <Grid item xs={6}>
            <TextField
              color='grey'
              focused
              fullWidth
              select
              defaultValue="US"
              value={credentials.country}
              onChange={handleChange}
              label="Country *"
              name="country"
              variant="outlined"
              className={(errors.countryIsEmpty) && "input-error"}
            >
              <MenuItem value="" disabled>
                Country
              </MenuItem>
              {countryList().getData().map(option => (
                <MenuItem key={option.value} value={option.value}>
                  {option.label}
                </MenuItem>
              ))}
            </TextField>
            {/* Country Errors */}
            {errors.countryIsEmpty &&
              <small className={classes.error}>{errors.countryIsEmpty}</small>
            }
          </Grid>

          <Grid item xs={6}>
            <TextField
              color='grey'
              focused
              variant="outlined"
              required
              fullWidth
              name="password"
              label="Password"
              type="password"
              id="password"
              onChange={(e) => { handleChange(e); setShow(true) }}
              inputProps={{ maxLength: 50 }}
              className={(errors.passIsEmpty) && "input-error"}
            />
            {/* Password Errors */}
            {errors.passIsEmpty &&
              <small className={classes.error}>{errors.passIsEmpty}</small>
            }
          </Grid>

          <Grid item xs={6}>
            <TextField
              focused
              color='grey'
              variant="outlined"
              required
              fullWidth
              name="confirmPassword"
              label="Confirm Password"
              type="password"
              id="confirmPassword"
              autoComplete="off"
              onChange={handleChange}
              inputProps={{ maxLength: 50 }}
              className={(errors.passIsInvalid) && "input-error"
              }
            />
            {/* Password Errors */}
            {errors.passIsInvalid &&
              <small className={classes.error}>{errors.passIsInvalid}</small>
            }

          </Grid>

          {/* Password check list */}
          {show &&
            <Grid item xs={12} style={{ paddingLeft: '35px' }}>
              <Box
                sx={{
                  display: 'grid',
                  margin: 'auto',
                  gridTemplateColumns: '1fr 1fr',
                  width: '100%',
                }}
              >
                <Typography>
                  <PasswordChecklist
                    rules={["minLength", "specialChar", "number", "capital", "lowercase", "match"]}
                    style={{ fontSize: 12 }}
                    iconSize={8}
                    minLength={8}
                    value={credentials.password}
                    valueAgain={credentials.confirmPassword}
                    onChange={(isValid) => { setIsPassValid(isValid) }}
                  />
                </Typography>
              </Box>
            </Grid>
          }


          <Grid item xs={12} paddingBottom={3}>
            <FormGroup>
              <Typography><a href={TCpdf} without rel='noopener noreferrer' target="_blank" onClick={() => setIsViewed(true)}> Read our Cloud Services Agreement</a></Typography>
              <Tooltip title={!isViewed ? "Please read our Cloud Services Agreement first by clicking on the link above" : ""} placement="top-end" enterTouchDelay='100'>
                <FormControlLabel
                  componentsProps={{ typography: { fontSize: '11pt' } }}
                  required
                  control={
                    <Checkbox
                      name='agreedToTerms'
                      checked={checked}
                      disabled={!isViewed}
                      onChange={(e) => { handleChange(e); setChecked(e.target.checked) }}
                    />
                  }
                  label={'I have read and agreed to the Cloud Services Agreement'}
                />
              </Tooltip>
            </FormGroup>
            {errors.notyetAgreed &&
              <small className={classes.error}>{errors.notyetAgreed}</small>
            }
          </Grid>

        </Grid>

        <Button
          type="submit"
          // fullWidth
          variant="contained"
          color="primary"
          onClick={(e) => { handleSubmit(e); setShow(true) }}
        >
          {buttonStr}
        </Button>

        <Grid container justifyContent="flex-end" paddingTop={4}>
          <Stack direction="row" alignItems='center' spacing={2}>
            <Typography fontWeight='bold'>
              Already have an account?
            </Typography>
            <Button
              type="submit"
              variant="outlined"
              color="primary"
              size='small'
              onClick={() => {
                navigate('/login')
              }}
            >
              Login
            </Button>
          </Stack>
        </Grid>

      </Paper>

    </div>
  )
}


const mapStateToProps = (state) => {
  return {
    authMsg: state.auth.authMsg,
    textColor: state.auth.textColor
  }
}

const mapDispatchToProps = dispatch => {
  return {
    registerUser: (info) => dispatch(registerUser(info)),
    resetAuth: () => dispatch(resetAuth())
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Signup);