import React, { useState, useEffect } from "react";

import { connect } from "react-redux";

import axios from "axios";

import {
	Button,
	TextField,
	Dialog,
	DialogActions,
	DialogContent,
	DialogTitle,
	DialogContentText,
	Typography
} from "@mui/material";

import { resetPassword, updateUserInfo } from "../../../redux/auth/auth-actions";
import { useNavigate } from "react-router-dom";


const EditInfo = ({
	updateUserInfo,
	open,
	setOpen,
	onSave,
	actionName,
	userId,
	firstName,
	lastName,
	company,
	resetPassword,
	userEmail,
	authMsg
}) => {

	const [state, setState] = useState({ firstName: '', lastName: '', groupName: '' })
	const [buttonText, setButtonText] = useState("Save")
	const [message, setMessage] = useState('')
	const navigate = useNavigate()

	useEffect(() => {
		setState({
			firstName: firstName,
			lastName: lastName,
			company: company,
		});
	}, [firstName, lastName, company]);

	const handleInputChange = (e) => {
		setState(state => ({
			...state,
			[e.target.name]: e.target.value
		}));
	}

	const handleClose = () => {
		setOpen(false);
	};

	const updateInfo = async () => {
		try {
			await axios.post('/user-managers/updateUserInfo', {
				'userId': userId,
				'fieldsToUpdate': {
					"First_Name": state.firstName,
					"Last_Name": state.lastName,
					"Group_Name": state.company,
				}
			})
				.then(onSave)
				.then(setTimeout(2000))
				.catch((error) => {
					return error
				})
		} catch (error) {
			console.error({ "UpdateUser AuthAction Error": error })
		}
	};
	if (actionName === "updatePersonalInfo") {
		return (
			<div>
				<Dialog
					open={open}
					onClose={handleClose}
					PaperProps={{ sx: { boxShadow: "None" } }}
				>
					<DialogTitle id="alert-dialog-title">
						Update Personal Information
					</DialogTitle>
					<DialogContent>
						<TextField
							type="text"
							name="firstName"
							label='First name'
							margin="dense"
							InputLabelProps={{ shrink: true }}
							fullWidth
							variant="outlined"
							size="small"
							value={state.firstName}
							inputProps={{maxLength: 50}}
							onChange={e => { handleInputChange(e) }}
						/>
						<TextField
							type="text"
							name="lastName"
							label='Last name'
							margin="dense"
							InputLabelProps={{ shrink: true }}
							fullWidth
							variant="outlined"
							size="small"
							value={state.lastName}
							inputProps={{maxLength: 50}}
							onChange={e => { handleInputChange(e) }}
						/>
						<TextField
							type="text"
							name="company"
							label='Company'
							margin="dense"
							InputLabelProps={{ shrink: true }}
							fullWidth
							variant="outlined"
							size="small"
							value={state.company}
							inputProps={{maxLength: 50}}
							onChange={e => { handleInputChange(e) }}
						/>
						<DialogActions>
							<Button variant="outlined" onClick={handleClose} color="primary">
								Close
							</Button>
							<Button
								variant="outlined"
								disabled={!state.firstName || !state.lastName || !state.company}
								onClick={() => {
									setButtonText("Saving...");
									updateInfo();
									updateUserInfo(state.firstName, state.lastName, state.company);
									setTimeout(function () {
										handleClose()
										setButtonText("Save")
									}, 2100);
								}}
								color="primary"
							>
								{buttonText}
							</Button>
						</DialogActions>
					</DialogContent>
				</Dialog>
			</div>
		);
	} else if (actionName === "updatePassword") {
		return (
			<div>
				<Dialog open={open} onClose={handleClose} PaperProps={{ sx: { boxShadow: "None" } }}>
					<DialogTitle id="alert-dialog-title">
						Do you want to reset your password?
						You will need to login again
					</DialogTitle>
					<DialogContent>
						<DialogContentText>
							<Typography color='red' align='center'>
								{message}
							</Typography>
						</DialogContentText>
						<DialogActions>
							<Button variant="outlined" onClick={handleClose} color="primary">
								Close
							</Button>
							<Button
								variant="outlined"
								onClick={() => {
									resetPassword(userEmail)
									handleClose()
									setMessage()
									navigate('/logout')
								}}
								color="primary"
							>
								Reset
							</Button>
						</DialogActions>
					</DialogContent>
				</Dialog>
			</div>
		);
	}
};

const mapDispatchToProps = (dispatch) => {
	return {
		resetPassword: (email) => dispatch(resetPassword(email)),
		updateUserInfo: (firstName, lastName, company, receiveUpdates) => dispatch(updateUserInfo(firstName, lastName, company, receiveUpdates)),
	};
};

export default connect(null, mapDispatchToProps)(EditInfo);