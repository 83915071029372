import isEmail from "validator/lib/isEmail";
import whiteList from "./white-listed-domains"

const validate = (credentials, isReset, isNewUser, isPassValid) => {
  const errors = {};

  // Check if email is not empty
  if (!credentials.email) {
    errors.emailIsEmpty = "Email address cannot be empty";
  } else {
    delete errors.emailIsEmpty;
  }

  // Check if email format is valid
  if (credentials.email && !isEmail(credentials.email)) {
    errors.emailFormatInvalid = "Email address is invalid";
  } else {
    delete errors.emailFormatInvalid;
  }

  // Don't check password if user is resetting password
  if (!isReset) {
    // Check if password is not empty
    if (!credentials.password) {
      errors.passIsEmpty = "Password cannot be empty";
    } else {
      delete errors.passIsEmpty;
    }
  }

  // Validations for new account only
  if (isNewUser) {
    const link = "https://wkf.ms/4354RVV";
    const message = `We are expanding our support for academic accounts. 
    If your email domain is not on our list, please refrain from registering for an Industry account, and complete our brief <a href="${link}" target="_blank">verification form</a> instead. 
    Allow up to one week for verification. Please use your institution's email; 
    all personal emails will be automatically rejected.`;

    if (credentials.email && isEmail(credentials.email) && credentials.userType === "Academia") {
      const domain = credentials.email.split('@')[1].toLowerCase()
      if (!whiteList.some(item => domain.includes(item))) {
        errors.emailIsNotEDU = message;
      } else {
        delete errors.emailIsNotEDU;
      }
    }

    // Check First Name
    if (!credentials.firstName) {
      errors.firstNameIsEmpty = "First name cannot be empty";
    } else {
      delete errors.firstNameIsEmpty;
    }

    // Check Last Name
    if (!credentials.lastName) {
      errors.lastNameIsEmpty = "Last name cannot be empty";
    } else {
      delete errors.lastNameIsEmpty;
    }

    // Check Job Title
    if (!credentials.jobTitle) {
      errors.jobTitleIsEmpty = "Company name cannot be empty";
    } else {
      delete errors.jobTitleIsEmpty;
    }

    // Check Country
    if (!credentials.country) {
      errors.countryIsEmpty = "Please select a country";
    } else {
      delete errors.countryIsEmpty;
    }

    // Check Account Type
    if (!credentials.userType) {
      errors.userTypeIsEmpty = "Please select an account type";
    } else {
      delete errors.userTypeIsEmpty;
    }

    // Check if password meets requirements for new user
    if (isNewUser && !isPassValid) {
      errors.passIsInvalid = "Password does not meet requirements";
    } else {
      delete errors.passIsInvalid;
    }

    // Check Terms & Conditions
    if (!credentials.agreedToTerms) {
      errors.notyetAgreed = "Terms and conditions have not been accepted";
    } else {
      delete errors.notyetAgreed;
    }
  }

  return errors;
};

export default validate;
