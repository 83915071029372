import React from 'react';
import { connect } from 'react-redux';
import { Paper } from '@mui/material';
import { makeStyles } from "@mui/styles";
import { Signin } from './utils';
import { Header } from '../../app/utils';
import { loginUser, resetPassword, resetAuth, registerRequest } from '../../redux/auth/auth-actions';

const useStyles = makeStyles(() => ({
  root: {
    width: '350px',
    padding: '50px',
    direction: "column",
    alignItems: "center",
    justifyContent: "center",
  },
}));

const Login = ({
  isAuthenticated,
  loginUser,
  resetPassword,
  authMsg,
  resetAuth,
  redirect,
  textColor,
}) => {
  const classes = useStyles();

  return (
    <div style={{ padding: '100px' }}>
      <Header />
      <Paper className={classes.root}>
        <Signin
          isAuthenticated={isAuthenticated}
          loginUser={loginUser}
          resetPassword={resetPassword}
          authMsg={authMsg}
          resetAuth={resetAuth}
          redirect={redirect}
          registerRequest={registerRequest}
          textColor={textColor}
        />
      </Paper>
    </div>
  );
}

const mapStateToProps = (state) => ({
  authMsg: state.auth.authMsg,
  isAuthenticated: state.auth.isAuthenticated,
  redirect: state.auth.redirect,
  textColor: state.auth.textColor,
});

const mapDispatchToProps = {
  loginUser,
  resetPassword,
  resetAuth,
  registerRequest,
};

export default connect(mapStateToProps, mapDispatchToProps)(Login);
