import React from "react";
import HelpIcon from "@mui/icons-material/Help";
import { Tooltip, Typography } from "@mui/material";
import Parser from "html-react-parser";
import { styles } from "../../../app";

const JobInfo = ({
  stem,
  fileName,
  jobName,
  jobOptions,
  paramPenaltyScore,
  chargePenaltyScore,
  jobFailed,
  userType,
}) => {
  const link = "https://ffparam.silcsbio.com";
  const message = `
    The FFParam program can be used to facilitate the optimization of parameters for molecules with high "Param" and/or "Charge" penalties. 
    Visit <a href="${link}" target="_blank">FFParam</a> to learn more.
  `;
  const showMessage =
    !jobFailed &&
    userType === "Academia" &&
    (paramPenaltyScore > 50 || chargePenaltyScore > 50);

  return (
    <Typography
      style={{
        minHeight: "60vh",
        maxHeight: "60vh",
        overflowY: "scroll",
        scrollBehavior: "smooth",
        lineHeight: "2",
        fontSize: "18px",
      }}
    >
      <table>
        <tbody>
          <tr>
            <td>
              <b>Uploaded Mol2</b>
            </td>
            <td>: {fileName}</td>
          </tr>
          <tr>
            <td>
              <b>Job Name</b>
            </td>
            <td>: {jobName ? jobName : stem}</td>
          </tr>
          <tr>
            <td>
              <b>Options Selected</b>
            </td>
            <td>:</td>
          </tr>
        </tbody>
      </table>

      <table style={{ paddingLeft: "50px" }}>
        <tbody>
          <tr>{jobOptions.gromacs && <td>- Add GROMACS Format </td>}</tr>
          <tr>{jobOptions.mol2out && <td>- Output Mol2 </td>}</tr>
          <tr>{jobOptions.warning && <td>- Include Warnings </td>}</tr>
          <tr>{jobOptions.debug && <td>- Include Debug Info </td>}</tr>
          <tr>{jobOptions.copyParams && <td>- Copy Existing Params </td>}</tr>
        </tbody>
      </table>

      <pre></pre>

      <table style={{ color: styles.primaryColor }}>
        <tbody>
          {!jobFailed && userType !== "Academia" && (
            <tr>
              <td>
                <b>Cost to Run Job</b>
              </td>
              <td>: $350 </td>
            </tr>
          )}

          <tr>
            <td>
              <b>Param Penalty</b>
              <Tooltip
                title="Penalty is the highest penalty score of the associated parameters. Penalties lower than 10 indicate the analogy is fair; penalties between 10 and 50 mean some basic validation is recommended; penalties higher than 50 indicate poor analogy and mandate extensive validation/optimization. See FAQ for more in-depth explanation."
              >
                <HelpIcon color="primary" fontSize="xsmall" />
              </Tooltip>
            </td>
            <td>: {paramPenaltyScore}</td>
          </tr>

          <tr>
            <td>
              <b>Charge Penalty</b>
              <Tooltip
                title="Penalty is the highest penalty score of the associated parameters. Penalties lower than 10 indicate the analogy is fair; penalties between 10 and 50 mean some basic validation is recommended; penalties higher than 50 indicate poor analogy and mandate extensive validation/optimization. See FAQ for more in-depth explanation."
              >
                <HelpIcon color="primary" fontSize="xsmall" />
              </Tooltip>
            </td>
            <td>: {chargePenaltyScore}</td>
          </tr>
        </tbody>
      </table>

      {showMessage && (
        <Typography paddingTop={2} paddingRight={4}>
          {React.createElement("div", null, Parser(message))}
        </Typography>
      )}
    </Typography>
  );
};

export default JobInfo;
