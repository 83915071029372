import React from "react";
import { Box, Link } from "@mui/material";
import Equation from './cgenff-equation.png'


const Background = () => {

	return (
		<>
			<Box sx={{ fontWeight: 'bold', fontSize: 18 }}>
				This CGenFF web app was designed for users with an understanding of molecular modeling, 
				molecular dynamics simulations, and the force field parameters required for molecular simulations.
			</Box>

			<p>
				The CHARMM General Force Field (CGenFF) covers a wide range of chemical groups present in 
				biomolecules and drug-like molecules, including a large number of heterocyclic scaffolds. 
				The parametrization philosophy behind the force field focuses on 
				quality at the expense of transferability, with the implementation concentrating on an 
				extensible force field.
			</p>

			<p>
				The CGenFF program uses the CHARMM additive potential energy function to calculate the energy 
				as a function of the Cartesian coordinates of the system, as shown below.
			</p>
			<div style={{ display: 'flex', justifyContent: 'center'}}>
				<img alt='' src={Equation} width="600px"/>
			</div>
			<p>
				The bonded portion of the potential energy function consists of terms for the bonds,
				valence angles, torsion or dihedral angles, improper dihedral angles, and a Urey-Bradley term,
				where 𝑏<sub>0</sub>, 𝜃<sub>0</sub>, 𝜙<sub>0</sub>, and 𝑟<sub>1,3;0</sub>, respectively, are the bond, angle, 
				improper and Urey-Bradley equilibrium	values, the 𝐾’s are the corresponding force constants, and 𝑛 and 𝛿 are the dihedral multiplicity and phase.
				The nonbonded portion consists of an electrostatic term, with 𝑞<sub>𝑖</sub> and 𝑞<sub>𝑗</sub> being the respective partial atomic charges on atoms 𝑖 and 𝑗, 
				and a van der Waals (vdW) term, which is treated by the Lennard-Jones (LJ) 6-12 potential in which 𝜖<sub>𝑖𝑗</sub> is the well depth, 𝑅<sub>min,𝑖𝑗</sub> is the radius, and 𝑟<sub>𝑖𝑗</sub> is the distance between atoms 𝑖 and 𝑗.
			</p>

			<p>
				Simulation of typical molecular systems of interest requires large numbers of parameters.
				To make the assignment of these parameters practical, force fields require atom types to be assigned
				to all the atoms in the system, with the parameters associated with combinations of atom types.
				For instance, the parameter list will contain 𝐾<sub>𝜙</sub>, 𝑛, and 𝛿 values for the dihedral parameters
				associated with all combinations of four atom types that occur in the molecules supported by the force field.
				Thus, the first step of assigning parameters for a chemical system is assigning atom types to that system.
			</p>

			<p>
				The CGenFF program employs an algorithm that can automatically assign atom types to a given molecule. 
				The atom typer is rule-based and programmable, making it easy to implement complex atom typing rules 
				and to update the atom typing scheme as the force field grows. In the event that the CGenFF force field 
				file does not explicitly contain a bonded parameter required for simulating the molecule, 
				the CGenFF program will assign bonded parameters by substituting analogous atom types in the definition 
				of the desired parameter. A penalty is associated with every substitution and the existing parameter 
				with the lowest total penalty is chosen as an approximation for the desired parameter. 
				Charges are assigned using an extended bond-charge increment scheme that is able to capture inductive 
				and mesomeric effects across up to 3 bonds.
			</p>

			<p>
				Visit <Link color="#3557a6" href="https://docs.silcsbio.com/2024/cgenff/usage.html" target="_blank">CGenFF Suite Docs</Link> to see more details.
			</p>
		</>
	);
};

export default Background;