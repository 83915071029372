import {
	SAVE_MOL2FILE_NAME,
	SAVE_CENTER,
	SAVE_JOB_NAME,
	SAVE_JOB_OPTIONS,
	JOB_FAILED,
	RESET_JOB,
} from './job-types'

const initialState = {
	jobInfo: {
		jobId: '',
		fileName: '',
		stem: '',
		jobName: '',
		centerx: '',
		centery: '',
		centerz: ''
	},
	jobOptions: {
		gromacs: false,
		mol2out: true,
		warning: true,
		debug: true,
		copyParams: false,
	},
	jobFailed: false,
}

const jobReducer = (state = initialState, action) => {
	switch (action.type) {
		case SAVE_MOL2FILE_NAME:
			return {
				...state,
				jobInfo: {
					...state.jobInfo,
					jobId: action.payload.jobId,
					fileName: action.payload.fileName,
					stem: action.payload.stem
				}
			};

		case SAVE_CENTER:
			return{
				...state,
				jobInfo: {
					...state.jobInfo,
					centerx: action.payload.centerx,
					centery: action.payload.centery,
					centerz: action.payload.centerz,
				}
			}

		case SAVE_JOB_NAME:
			return {
				...state,
				jobInfo: {
					...state.jobInfo,
					jobName: action.payload.jobName
				}
			};

		case SAVE_JOB_OPTIONS:
			if (action.payload.name === 'mol2out') {
				return {
					...state,
					jobOptions: {
						...state.jobOptions,
						mol2out: action.payload.value
					}
				}
			}
			else if (action.payload.name === 'warning') {
				return {
					...state,
					jobOptions: {
						...state.jobOptions,
						warning: action.payload.value
					}
				}
			}
			else if (action.payload.name === 'debug') {
				return {
					...state,
					jobOptions: {
						...state.jobOptions,
						debug: action.payload.value
					}
				}
			}
			else if (action.payload.name === 'copyParams') {
				return {
					...state,
					jobOptions: {
						...state.jobOptions,
						copyParams: action.payload.value
					}
				}
			}
			else if (action.payload.name === 'gromacs') {
				return {
					...state,
					jobOptions: {
						...state.jobOptions,
						gromacs: action.payload.value
					}
				}
			}

		case JOB_FAILED:
			return {
				...state,
					jobFailed: action.payload.jobFailed
			};
				
		case RESET_JOB:
			return { ...initialState }

		default:
			return { ...state };
	}
};

export default jobReducer;